import Dropdown from 'rc-dropdown';
import React from 'react';
import "./DropDown.css"

interface menuItem {
    label: any
    onClick: any
}

interface IDCMDropDown {
    menuItems: menuItem[]
    menuLabel: any
    title?:any
}

export default function DCMDropdown(props: IDCMDropDown) {

    const menuItems = () => {
        return (
            <div className="bg-white dcm-dropdown">
                {
                    props.title
                }
                {
                    props.menuItems.map(menuItem => {
                        return (<div className="cursor-pointer dcm-dropdown-item" onClick={menuItem.onClick}>
                            {menuItem.label}
                        </div>)
                    })
                }
            </div>
        )
    }

    return (
        <Dropdown
            trigger={['click']}
            overlay={menuItems}
            animation="slide-up"
        >
            {props.menuLabel}
        </Dropdown>
    )
}