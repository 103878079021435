import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ResponsesServices } from '../../../../Services/responsesServices';
import SelectedOption from './SelectedOption';

const Response = () => {
    const params: any = useParams();
    const [mappedUser, setMappedUser] = useState<any>();

    const getUserResponse = async () => {
        await ResponsesServices.getUserResponseByMappingId(params.mappingId).then((res) => {
            if (res.status === 200) {
                setMappedUser(res.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const formatDate = (date: any) => {
        return ((moment(date)).format('DD-MMM-YYYY'))
    }

    console.log(mappedUser, "mapped");



    useEffect(() => {
        getUserResponse();
    }, [])

    return (
        <>
            {mappedUser && mappedUser.length > 0 ? mappedUser.map((data: any) => {
                return (
                    <Card className="mt-4 mb-2">
                        <Card.Body>
                            Mark for review:  <span>{data.markedForReview === true ? "Yes" : "No"}</span><br />
                            Created at: <span>{formatDate(data.createdAt)}</span><br/>
                            Updated at: <span>{formatDate(data.updatedAt)}</span>
                            {data.selectedOption.map((data: any) => {
                                return <SelectedOption data={data} />
                            })}
                        </Card.Body>
                    </Card>
                )
            }) : ""}
        </>

    )
}

export default Response