import { AnyAaaaRecord } from 'dns';
import React from 'react'
import { Button, Card, Form, Image } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { ResultSerivces } from 'Services/resultService';
import ExamQuestionStatement from './Question.statement';

interface IQuestionCard {
    data: any[]
    setAnswers: any
    reloadAnswers: any
    examId: string
    answers: any[]
}

const QuestionCard = (props: IQuestionCard) => {

    const onSave = async (questionId: any) => {

        const question_data = props.answers.find((answer: any) => answer.questionId === questionId);

        if(!question_data){
            toast.error("Please select answer");
            return;
        }

        await ResultSerivces.markQuestionResult(props.examId, questionId, question_data.correctOption, question_data.isDeleted).then(res => {
            if (res.status === 200) {
                toast.success("Question Saved Successfully");
                props.reloadAnswers()
            }
        }).catch(e=>{
            console.error(e.response.data);
            toast.error(e.response.data || "Something went Wrong");
        })
    }

    const onAnswerChange = (optionId: any, questionId: any, e: any) => {
        
        let answer = props.answers.find((answer: any) => answer.questionId === questionId);
        if (!answer) {
            answer = {
                correctOption: [],
                isDeleted: false,
                questionId: questionId
            }
        }
        const other_answer = props.answers.filter((answer: any) => answer.questionId !== questionId);

        const old_correctOption = answer.correctOption.filter((correct: any) => correct != optionId)

        let newCorrectOption = [];

        if (e.target.checked) {
            newCorrectOption = [...old_correctOption, optionId];
        }
        else {
            newCorrectOption = [...old_correctOption];
        }

        props.setAnswers([...other_answer, {
            ...answer,
            correctOption: newCorrectOption
        }]);

    }

    const setIsDeleted = (questionId: any, e: any) => {
        let answer = props.answers.find((answer: any) => answer.questionId === questionId);
        if (!answer) {
            answer = {
                correctOption: [],
                isDeleted: false,
                questionId: questionId
            }
        }
        const other_answer = props.answers.filter((answer: any) => answer.questionId !== questionId);

        props.setAnswers([...other_answer, {
            ...answer,
            isDeleted: e.target.checked
        }]);
    }

    return (
        <>
            {props.data && props.data.length > 0 ? props.data.map((question: any, q_index: number) => {
                const answer = props.answers && props.answers.find((answer: any) => answer.questionId === question._id);

                return (
                    <Card className="shadow-sm p-3 mb-5 bg-white rounded">
                        <Card.Body>
                            <div>
                                {question && question.statement.map((statement: any, index: number) => {
                                    return (
                                        <div>
                                            <ExamQuestionStatement statement={statement.question} index={q_index + 1} />
                                            {/* <span className="fw-bold">Q{index + 1} :- </span><span>{statement.question}</span> */}
                                        </div>
                                    )
                                })}
                                <hr />
                                <span className="fw-bold">Options :- </span>
                                {question && question.options.map((option: any, index: number) => {
                                    return (
                                        <div className="mt-2">
                                            <div className="d-flex align-items-center mb-2">
                                                <span className="fw-bold" style={{ fontSize: "17px" }}>{index + 1 + "."} </span><span style={{ fontSize: "17px" }}>{" "}{option.choice}</span>
                                                <Form.Check
                                                    type="checkbox"
                                                    className="ms-2"
                                                    style={{ fontSize: "17px" }}
                                                    label={option.value}
                                                    checked={answer && answer.correctOption && answer.correctOption.includes(option._id)}
                                                    onChange={(e) => onAnswerChange(option._id, question._id, e)}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}

                                <hr />
                                <Form.Text className="text-muted">
                                    {/* We'll never share your email with anyone else. */}
                                    If question is wrong please click the below Checkbox.
                                </Form.Text>
                                <div className='d-flex justify-content-between align-item-center'>
                                    <div>
                                        <div className="d-flex align-item-center">
                                            <p className="fw-bold mt-3 text-danger">Delete {":-"}</p>
                                            <div className="d-flex align-items-center">
                                                <Form.Check
                                                    type="checkbox"
                                                    className="ms-2"
                                                    style={{ fontSize: "17px" }}
                                                    label="Yes"
                                                    checked={answer && answer.correctOption && answer.isDeleted}
                                                    onChange={(e) => setIsDeleted(question._id, e)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                        <Button variant="success" onClick={() => {onSave(question._id)}}>Save</Button>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card >
                )
            }) : "No question found"}

        </>
    )
}

export default QuestionCard

// <Card className="shadow-sm p-3 mb-5 bg-white rounded">
//                 <Card.Body>
//                     <div>
//                         <div>
//                             <span className="fw-bold">Q1 :- </span>
//                             <div>
//                                 <Image src='https://images.unsplash.com/photo-1671726805766-de50e4327182?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80' width="70%" height="10%" />
//                             </div>
//                         </div>
//                         <div className="mt-2">
//                             <span className="fw-bold">Options :- </span>
//                             {options && options.length > 0 ? options.map((option: any, index: number) => {
//                                 return (
//                                     <div className="d-flex align-items-center mb-2">
//                                         <span className="fw-bold" style={{ fontSize: "17px" }}>{index + 1 + "."} </span><span style={{ fontSize: "17px" }}>{" "}{option}</span>
//                                         <Form.Check
//                                             type="checkbox"
//                                             className="ms-2"
//                                             style={{ fontSize: "17px" }}
//                                             onChange={(e: any) => handleOption(e, option)}
//                                         />
//                                     </div>
//                                 )
//                             }) : "No option data available"}
//                         </div>
//                         <div className='d-flex justify-content-between align-item-center'>
//                             <div>
//                                 <div className="d-flex align-item-center">
//                                     <p className="fw-bold mt-3 text-danger">Delete {":-"}</p>
//                                     <div className="d-flex align-items-center">
//                                         <Form.Check
//                                             type="checkbox"
//                                             className="ms-2"
//                                             style={{ fontSize: "17px" }}
//                                             label="Yes"
//                                             onChange={handleDelete}
//                                         />
//                                         <Form.Check
//                                             type="checkbox"
//                                             className="ms-2"
//                                             style={{ fontSize: "17px" }}
//                                             label="No"
//                                             onChange={handleDelete}
//                                             checked={selectDelete}
//                                         />
//                                     </div>
//                                 </div>
//                                 <Form.Text className="text-muted">
//                                     {/* We'll never share your email with anyone else. */}
//                                     If any of question is wrong please click the above Checkbox.
//                                 </Form.Text>
//                             </div>
//                             <div>
//                                 <Button variant="success">Save</Button>
//                             </div>
//                         </div>
//                     </div>
//                 </Card.Body>
//             </Card >