import React from 'react';
import { Card } from 'react-bootstrap';
import "./Card.css";

interface ICardBasic {
    title?: any
    body: any
    bodyClass?: string
    cardClass?: string
    titleClass?: string
}

export default function CardBasic(props: ICardBasic) {
    return (
        <Card className={"xrg-card  " + (props.cardClass)}>
            <Card.Body>
                {props.title ?
                    <div className={props.titleClass}>
                        {props.title}
                    </div> 
                    
                    : " "
                }
                <div className={props.bodyClass}>
                    {props.body}
                </div>
            </Card.Body>
        </Card>
    )
}