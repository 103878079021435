import { faGear , faArrowRightArrowLeft , faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Topbar.css';

export default function TopBar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authKey');
    navigate('/login');
  };

  return (
    <>
      <Navbar bg="white" expand="lg" className="xrg-top-main ">
        <Navbar.Brand className="xrg-fw-900 cursor-pointer text-brand-color mx-3 ">
          <FontAwesomeIcon icon={faArrowLeft} size="sm" className="me-2" onClick={()=>navigate(-1)} />
        </Navbar.Brand>
        <Navbar.Brand className="xrg-fw-900 cursor-pointer text-brand-color">
          ADICOM
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="d-flex justify-content-end"
        >
          <Nav>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown" align="end">
              {/* <NavDropdown.Item >Action</NavDropdown.Item>
                            <NavDropdown.Item >Another action</NavDropdown.Item> */}
              <NavDropdown.Item>Admin Logged In</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout}>
                <span className="fw-bold">Log Out</span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
