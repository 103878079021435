import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CardBasic from '../../../components/Cards/Card.basic'
import { ExamServices } from '../../../Services/examServices'

interface IStatementValue {
    value: any,
    reload?: any
}

const StatementValueShow = ({ value, reload }: IStatementValue) => {

    const {questionId} = useParams();

    const deleteStatement = async() => {
        await ExamServices.deleteStatementById(questionId, value._id).then((res) => {
            if(res.status === 200) {
                toast.success("Statement Deleted Successfully");
                reload();
            }
        }).catch((err) => {
            console.log("Unable to delete statement");
        })
    }

    return (
        <>
            <CardBasic
                body={
                    <>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <div>
                                    Language: <b>{value.language}</b>
                                </div>
                                <div>
                                    Question: <b>{value?.question}</b>
                                </div>
                            </div>
                            <div>
                                <Button variant='danger' size="sm" onClick={deleteStatement}>
                                    <FontAwesomeIcon icon={faClose} /> Delete
                                </Button>
                            </div>
                        </div>
                    </>
                }
                cardClass="mt-3"
            />
        </>
    )
}

export default StatementValueShow