import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePagination } from 'react-table'
import { toast } from 'react-toastify'
import { ResponsesServices } from 'Services/responsesServices'
import Index from './Log/Index'

const LogsIndex = () => {

  const params: any = useParams();

  console.log(params)

  const [userData, setUserData] = useState();

  const getAllCompletedUsersForExam = async () => {
    await ResponsesServices.getAllResponsesByExamId(params.examId).then((res) => {
      if (res.status === 200) {
        setUserData(res.data);
      }
    }).catch((err) => {
      toast.error("Unable to fetch response")
    })
  }

  console.log(userData);


  useEffect(() => {
    getAllCompletedUsersForExam();
  }, [])
  return (
    <Index />
  )
}

export default LogsIndex