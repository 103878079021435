import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Col, Form, Row } from 'react-bootstrap';
import { ExamServices } from '../../Services/examServices';
import Sections from '../Sections';
import Tags from '../Tags';
import { Document } from "react-pdf";
import { toast } from 'react-toastify';

interface IEditExamModal {
  examId: string;
  reloadData: any;
  setShow: any;
  show: boolean;
}

interface IExamData {
  examName: string;
  startTime: Date;
  endTime: Date;
  subject: string;
  tags: string;
  createdBy: string;
  sections: any;
  instructions: string[];
  icon: string;
  instructionPdf: string;
}

export default function EditExamModal(props: IEditExamModal) {
  const [tags, setTags] = useState([]);
  const [layoutData, setLayoutData] = useState<any[]>([])
  const [examData, setExamData] = useState<IExamData>();
  const getExamDetailsById = async () => {
    await ExamServices.getExamById(props.examId).then((res) => {
      if (res.status === 200) {
        setExamData(res.data.data);
      }
    });
  };


  const getLayout = async () => {
    await ExamServices.getLayout().then((res) => {
        if (res.status == 200) {
            // console.log(res.data)
            const data = res.data.map((data: any) => {
              return {
                  value: data,
                  label: data
              }
          }); 
          setLayoutData(data);
        }
    }).catch((err) => {
        console.log(err.response);
    })
}

useEffect(() => {
  getLayout();
}, [])

// console.log(layoutData, "layout")

  // const handleInstructionAdd = () => {
  //   let current_Instructions: any = examData?.instructions;
  //   current_Instructions.push('');
  //   const new_examData: any = {
  //     ...examData,
  //     instructions: current_Instructions,
  //   };
  //   setExamData(new_examData);
  // };

  // const handleInstructionDelete = (index: number) => {
  //   let current_Instructions: any = examData?.instructions;
  //   current_Instructions.splice(index, 1);
  //   const new_examData: any = {
  //     ...examData,
  //     instructions: current_Instructions,
  //   };
  //   setExamData(new_examData);
  // };

  // const handleInstructionChange = (value: any, index: number) => {
  //   let current_Instructions: any = examData?.instructions;
  //   current_Instructions[index] = value;
  //   const new_examData: any = {
  //     ...examData,
  //     instructions: current_Instructions,
  //   };
  //   setExamData(new_examData);
  // };


  const handleInstructionChange = (event: any) => {
    const pdf = event.target.files[0];
    getBase64(pdf, (data: string) => {
      const oldData: any = {
        ...examData,
        instructionPdf: data,
      };
      setExamData(oldData);
    });
  };

  const getBase64 = (file: File, cb: Function) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
    };
  };
  const handleIconChange = (event: any) => {
    const icon = event.target.files[0];
    getBase64(icon, (data: string) => {
      const oldData: any = {
        ...examData,
        icon: data,
      };
      setExamData(oldData);
    });
  };


  useEffect(() => {
    if (props.examId) {
      getExamDetailsById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.examId]);

  const getDefaultValue = (date: any) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const handelChange = (e: any) => {
    const examDataOld: any = examData;
    setExamData({ ...examDataOld, [e.target.name]: e.target.value });
  };

  const onSaveClicked = async () => {
    await ExamServices.updateExam({ data: examData }, props.examId)
      .then((res) => {
        if (res.status === 200) {
          alert('Exam updated');
          props.reloadData();
          props.setShow(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSectionRemoval = (index: number) => {
    let section: any = examData?.sections;
    section.splice(index, 1);
    const new_examdata: any = { ...examData, sections: section };
    setExamData(new_examdata);
  };

  const handleSectionChange = (e: any, index: number) => {
    let current_sections: any = examData?.sections;
    current_sections[index] = {
      ...current_sections[index],
      [e.target.name]: e.target.value,
    };
    const new_examData: any = { ...examData, sections: current_sections };
    setExamData(new_examData);
  };

  const handleSectionAddition = () => {
    let current_sections: any = examData?.sections;
    current_sections.push({
      name: '',
      duration: 0,
    });
    const new_examData: any = { ...examData, sections: current_sections };
    setExamData(new_examData);
  };




  return (
    <>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => props.setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Exam
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="text-secondary fw-bold xrg-text-12">Exam Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter exam name"
                name="examName"
                onChange={handelChange}
                value={examData?.examName}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="text-secondary fw-bold xrg-text-12">Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Subject"
                name="subject"
                onChange={handelChange}
                value={examData?.subject}
              />
            </Form.Group>
            {/* <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="text-secondary fw-bold xrg-text-12">Start Time of Exam</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Subject"
                    name="startTime"
                    onChange={handelChange}
                    defaultValue={getDefaultValue(examData?.startTime)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="text-secondary fw-bold xrg-text-12">End Time of Exam</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Subject"
                    name="endTime"
                    onChange={handelChange}
                    defaultValue={getDefaultValue(examData?.endTime)}
                  />
                </Form.Group>
              </Col>
            </Row> */}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="text-secondary fw-bold xrg-text-12">Add / Change icon</Form.Label>
              <label htmlFor="exam-icon">
                {examData?.icon && (
                  <img
                    src={examData.icon}
                    alt={'Exam'}
                    className={'exam-icon'}
                    style={{
                      width: 100,
                      height: 100,
                      padding: '1rem',
                      cursor: 'pointer'
                    }}
                  />
                )}
              </label>
              <Form.Control
                type="file"
                name="subject"
                id={'exam-icon'}
                onChange={handleIconChange}
              />
            </Form.Group>
            <Tags
              tags={examData?.tags}
              setTags={(value: any) => {
                const e = {
                  target: {
                    value: value,
                    name: tags,
                  },
                };
                handelChange(e);
              }}
            />

            <hr />

            <div className="d-flex justify-content-between align-items-center">
              <div className="text-secondary fw-bold xrg-text-12">Sections</div>
              <Button onClick={handleSectionAddition} size="sm">
                Add Section
              </Button>
            </div>

            {examData?.sections.map((data: any, index: number) => {
              return (
                <Sections
                  sectionData={data}
                  sectionCount={index}
                  handleChange={(e: any) => handleSectionChange(e, index)}
                  removeSection={() => handleSectionRemoval(index)}
                  layoutOption={layoutData}
                />
              );
            })}

            <hr />

            <div className="d-flex justify-content-between align-items-center">
              <div className="text-secondary fw-bold xrg-text-12">Instructions</div>
              <label>
                {examData?.instructionPdf && (
                  <>
                    <a onClick={() => window.open(examData.instructionPdf)}> Download </a>
                  </>
                )}
              </label>
              <Form.Group>
                <Form.Control
                  type="file"
                  name="instructionPdf"
                  onChange={handleInstructionChange}
                />
              </Form.Group>
            </div>


            {/* {examData?.instructions.map((data: any, index: number) => {
              return (
                <div className="d-flex justify-content-between align-items-center my-3">
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-danger me-2"
                    onClick={() => handleInstructionDelete(index)}
                  />
                  <Form.Control
                    type="text"
                    placeholder="instructions"
                    name="value"
                    value={data}
                    onChange={(e: any) =>
                      handleInstructionChange(e.target.value, index)
                    }
                  />
                </div>
              );
            })} */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onSaveClicked}>
            Save Changes
          </Button>
          <Button
            onClick={() => {
              props.setShow(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
