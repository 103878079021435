import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal } from 'react-bootstrap';

import "./DicomViewModal.css";

interface IImageViewModal {
  show: boolean
  setShow: any
  imageData: any
}

export default function ImageViewModal(props: IImageViewModal) {

  return (
    <>
      <Modal show={props.show} fullscreen={true} onHide={() => props.setShow(false)} centered  className="dicom-modal-fullscreen">
        <Modal.Title className="text-white m-3"><FontAwesomeIcon icon={faClose} onClick={() => props.setShow(false)} className="me-3 dcm-link-cursor"/> Image Control View</Modal.Title>
        <Modal.Body>
          <div className='d-flex justify-content-center align-items-center'>
            <img src={props.imageData} style={{maxHeight: "80vh"}}/>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}