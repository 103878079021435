import React from 'react'
import { Card } from 'react-bootstrap'

interface ISelectedOption {
  data: any
}

const SelectedOption = ({ data }: ISelectedOption) => {
  return (
    <>
      <Card className="mt-4 mb-2">
        <Card.Body>
          Value : <span>{data.value === true ? "True": "False" && data.value}</span> <br/>
          Flag : <span>{data.flag ? data.flag === true ? "True": "False": "--"}</span>
        </Card.Body>
      </Card>
    </>
  )
}

export default SelectedOption