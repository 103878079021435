import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

interface IArguments {
  tab: any;
  setTab: any;
  handleSearchChange?: (value: string) => void;
  searchValue?: string;
  onlineCount: any
  offlineCount: any
  submittedCount: any
  blockedCount: any
  loggedCount: any
  absentCount: any
  userCount: any
}

export default function StudentNav({
  tab,
  setTab,
  handleSearchChange,
  searchValue,
  onlineCount,
  offlineCount,
  submittedCount,
  blockedCount,
  loggedCount,
  absentCount,
  userCount
}: IArguments) {

  return (
    < div className="col-auto d-flex  justify-content-between align-items-center " >
      <ul className="nav nav-tabs  header-tabs">
        <li className="nav-item">
          <div
            className={`nav-link cursor-pointer ${tab === 'all' && 'active'}`}
            onClick={() => setTab('all')}
          >
            All ({userCount && userCount})
          </div>
        </li>
        <li className="nav-item">
          <div
            className={`nav-link cursor-pointer ${tab === 'logged' && 'active'
              }`}
            onClick={() => setTab('logged')}
          >
            Active ({loggedCount && loggedCount})
          </div>
        </li>
        <li className="nav-item">
          <div
            className={`nav-link cursor-pointer ${tab === 'online' && 'active'
              }`}
            onClick={() => setTab('online')}
          >
            Online ({onlineCount})
          </div>
        </li>
        <li className="nav-item">
          <div
            className={`nav-link cursor-pointer ${tab === 'offline' && 'active'
              }`}
            onClick={() => setTab('offline')}
          >
            Offline ({offlineCount})
          </div>
        </li>
        <li className="nav-item">
          <div
            className={`nav-link cursor-pointer ${tab === 'submitted' && 'active'
              }`}
            onClick={() => setTab('submitted')}
          >
            Submitted ({submittedCount})
          </div>
        </li>
        <li className="nav-item">
          <div
            className={`nav-link cursor-pointer ${tab === 'absent' && 'active'
              }`}
            onClick={() => setTab('absent')}
          >
            Absent ({absentCount})
          </div>
        </li>
        <li className="nav-item">
          <div
            className={`nav-link cursor-pointer ${tab === 'blocked' && 'active'
              }`}
            onClick={() => setTab('blocked')}
          >
            Blocked ({blockedCount})
          </div>
        </li>
      </ul>
      <div>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">Search</InputGroup.Text>
          <Form.Control
            placeholder="Name"
            type={'search'}
            onChange={(e: any) => handleSearchChange?.(e.target.value)}
            value={searchValue}
          />
        </InputGroup>
      </div>
    </div >
  );
}
