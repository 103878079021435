import React from 'react';
import logo from './logo.svg';
import './App.css';

import { BrowserRouter } from 'react-router-dom';
import MainRouter from './Router';
import { ToastContainer } from "react-toastify";
import "./assets/css/tables.css"
import 'rc-dropdown/assets/index.css';
import "react-datetime/css/react-datetime.css";
import { Loader } from './components/Loader/Loader';
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <div className="App">
      <Loader>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <BrowserRouter>
          <MainRouter />
        </BrowserRouter>
      </Loader>
    </div>
  );
}

export default App;
