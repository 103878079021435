import React, { useEffect, useState } from 'react'
import { Card, Button, Badge, Row, Col, Table } from 'react-bootstrap';
import moment from 'moment';
import { ExamServices } from '../../../../Services/examServices';
import EditExamModal from '../../../../components/Modals/EditExamModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../../../routes/routes';
import CardBasic from '../../../../components/Cards/Card.basic';
import Toolbar from '../../../../components/Toolbar/Toolbar';
import BooleanStatus from '../../../../components/Badge/BooleanStatus';
import BooleanIcon from 'components/Badge/BooleanIcon';
import { faCheckCircle, faClose, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


interface IExamData {
    examData: any,
    reloadData: any
}

export default function Examcard({ examData, reloadData }: IExamData) {
    const navigate = useNavigate();

    const options = { hour: 'numeric', minute: 'numeric' };
    const formatDate = (date: any) => {
        return ((moment(date)).format('DD-MM-YYYY HH:mm a'))
    }


    const [showEditModal, setShowEditModal] = useState(false);
    const [examId, setExamId] = useState("");


    const handelDeleteClick = async (_id: string) => {
        await ExamServices.deleteOneExam(_id)
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Exam deleted successfully");
                }
                reloadData();
            })
            .catch((err) => {
                toast.error(err);
            })
    }



    const examLive = async () => {
        await ExamServices.makeExamLive(examData._id).then((res) => {
            reloadData();
        }).catch((err) => {
            console.log(err);

        })
    }

    return (
        <>
            <CardBasic
                title={
                    <div className='d-flex justify-content-between align-items-center'>
                        <h6 className='fw-bold'>
                            <div>
                                Exam Name: {examData.examName}
                            </div>
                            <div>
                                {examData &&
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <div className='d-flex justify-content-between mt-3'>
                                            <div className='d-flex justify-content-start align-items-center'>

                                                <span className="me-1">Download Data:</span>
                                                <BooleanIcon
                                                    values={{ true: faCheckCircle, false: faTimesCircle }}
                                                    status={examData.downloadData}
                                                />
                                            </div>
                                            <div className='d-flex justify-content-start align-items-center ms-4'>
                                                <span className="me-1">Start Allowed:</span>
                                                <BooleanIcon
                                                    values={{ true: faCheckCircle, false: faTimesCircle }}
                                                    status={examData.startAllowed}
                                                />
                                            </div>
                                            <div className='d-flex justify-content-start align-items-center ms-4'>

                                                <span className="me-1">User Show:</span>
                                                <BooleanIcon
                                                    values={{ true: faCheckCircle, false: faTimesCircle }}
                                                    status={examData.userShow}
                                                />
                                            </div>

                                        </div>
                                    </div>}
                            </div>
                        </h6>
                        <Toolbar
                            size="sm"
                            values={[
                                {
                                    onClick: () => handelDeleteClick(examData._id),
                                    value: "Delete",
                                    variant: "danger"
                                }, {
                                    onClick: () => {
                                        setExamId(examData._id);
                                        setShowEditModal(true)
                                    },
                                    value: "Edit",
                                    variant: "secondary"
                                }, {
                                    onClick: () => navigate(baseUrl + "/exam/" + examData._id),
                                    value: "View",
                                    variant: "primary"
                                }, {
                                    onClick: () => navigate(baseUrl + "/exam/getExamQuestions/" + examData._id),
                                    value: "Exam Questions",
                                    variant: "warning"
                                }
                            ]}
                        />
                    </div>}
                body={
                    <>
                        <hr style={{ marginTop: "20px" }} />

                        <Row>

                            <Col md={8}>
                                <Row>
                                    <Col md={3}>
                                        Subject:
                                    </Col>
                                    <Col md={9} className="fw-bold">
                                        {examData.subject}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        Created at:
                                    </Col>
                                    <Col md={9}>
                                        {formatDate(examData.createdAt)}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={3}>
                                        Last updated at:
                                    </Col>
                                    <Col md={9}>
                                        {new Date(examData.updatedAt).toLocaleDateString()}
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4}>
                                {
                                    examData.tags ?
                                        examData.tags.map((tag: any) => {
                                            return (
                                                <Badge bg="secondary" className='me-3 mt-2'>{tag}</Badge>
                                            )
                                        })
                                        : ''
                                }
                            </Col>

                        </Row>
                        <hr />
                        <h6 className="fw-bold">
                            Exam Timings:
                        </h6>
                        <Table borderless responsive="md" className="xrg-text-12" size="sm">
                            <thead>
                                <tr>
                                    <th>Time Type</th>
                                    <th>Start Time/From</th>
                                    <th>End Time/To</th>
                                    <th>Duration</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-primary fw-bold">
                                        Enabled Time
                                    </td>
                                    <td className="text-secondary">
                                        {formatDate(examData.startTime)}
                                    </td>
                                    <td className="text-secondary">
                                        {formatDate(examData.endTime)}
                                    </td>
                                    <td className="text-secondary">
                                        ---
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-primary fw-bold">
                                        Duration Time
                                    </td>
                                    <td className="text-secondary">
                                        {formatDate(examData.userAllowedStartTime)}
                                    </td>
                                    <td className="text-secondary">
                                        {formatDate(examData.userAllowedEndTime)}
                                    </td>
                                    <td className="text-secondary">
                                        {moment(examData.userAllowedEndTime).diff(moment(examData.userAllowedStartTime), "minutes") + " min(s)"}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-primary fw-bold">
                                        Gate Closing Time
                                    </td>
                                    <td className="text-secondary">
                                        {formatDate(examData.userAllowedTillTime)}
                                    </td>
                                    <td className="text-secondary">
                                        ---
                                    </td>
                                    <td className="text-secondary">
                                        ---
                                    </td>
 
                                </tr>
                            </tbody>
                        </Table>



                    </>
                }
            />
            <EditExamModal
                setShow={setShowEditModal}
                examId={examId}
                show={showEditModal}
                reloadData={reloadData}
            />
        </>
    )
}