import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

interface IToolbarItems {
    value: any
    onClick?:any
    variant?: any
    disabled?: boolean
}

interface IToolbar{
    values: IToolbarItems[]
    size?: any
}

export default function Toolbar(props: IToolbar) {
    return (
        <>
            <ButtonGroup size={props.size || "lg"}>
                {props.values.map((data)=>{
                    return (
                        <Button className = "ms-2" variant={data.variant || "primary"} onClick={data.onClick} disabled={data.disabled}>{data.value || "Meta"}</Button>
                    )
                })}
            </ButtonGroup>
        </>
    )
}