import React, { useState } from 'react'
import { Badge, Form, Button, Container } from 'react-bootstrap'

interface ITags {
    tags: any,
    setTags: any
}
export default function Tags({ tags, setTags }: ITags) {

    const [currentTag, setCurrentTag] = useState('');

    const handelClick = () => {
        setTags([...tags, currentTag])
        setCurrentTag('');
    }

    return (
        <>
            {
                tags ?
                    tags.map((tag: string) => {
                        return (
                            <Badge className="me-2" bg="success">{tag}</Badge>
                        )
                    })
                    : ""
            }
            <Form.Group className="mb-3 mt-2" controlId="exam">
                <Form.Label className="text-secondary fw-bold xrg-text-12">Exam Tags</Form.Label>
                <Form.Control type="text" placeholder="Enter text" onChange={(e) => { setCurrentTag(e.target.value) }} value={currentTag} />
            </Form.Group>
            <Button variant="secondary" type="button" onClick={handelClick} size="sm">
                Add
            </Button>
        </>
    )
}