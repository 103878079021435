import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Auth from './Auth/auth'
import Login from './view/login/Login'
import PrivateRoutes from './view/private/Private.index'
import adminRoutes from './routes/routes'
import IRouter from './interfaces/IRouter'


function PrivateRouter({children}: any) {
    const auth = Auth.checkAuth();
    return auth ? <PrivateRoutes /> : <Navigate to="/login" />;
}


export default function MainRouter() {
    return (
        <>
            <Routes>
                {/*path is dashboard the render privateRounter */}
                <Route path="/admin-dash" element={<PrivateRouter />}>
                    {adminRoutes.map((data: IRouter) => {
                        // data will follow poperty of IRouter
                        return (
                            <Route
                                path={data.path + "/*"}
                                element={<data.element/>}
                            />
                        )
                    })}
                </Route>
                <Route path="/login" element={<Login/>} />
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </>
    )
}