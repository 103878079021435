import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Container, Form } from 'react-bootstrap'
import { ExamServices } from '../../../Services/examServices'
import { toast } from 'react-toastify'
import { errorResponseHelper } from '../../../helpers/request.helper'
import CardBasic from '../../../components/Cards/Card.basic'
import QuestionValueShow from '../../../components/Question/QuestionValueShow'
import StatementValueShow from './StatementValueShow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'


const QuestionStatement = () => {

    const params: any = useParams();

    const [questionData, setQuestionData] = useState<any>();
    const [examLanguages, setExamLanguages] = useState<string[]>([]);

    const [type, setType] = useState<any>();
    const [selectedLanguage, setSelectedLanguage] = useState<any>();
    const [statement, setStatement] = useState<any>();

    const uploadHandler = async (e: any) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("type", "text");
        formData.append("language", selectedLanguage);
        formData.append("statement", statement);

        await ExamServices.addQuestionToQuestion(params.questionId, formData).then((res) => {
            if (res.status == 200) {
                toast.success("Question added successfully")
                getQuestionByQuestionId()
            }
        }).catch((err) => {
            errorResponseHelper(err)
        })

    }

    const getQuestionByQuestionId = async () => {
        await ExamServices.getQuestionById(params.questionId).then(res => {
            if (res.status == 200) {
                setQuestionData(res.data.data.statement)
            }

        }).catch(e => {
            errorResponseHelper(e)
        })
    }

    const getExamLanguages = async () => {
        await ExamServices.getExamLanguage(params.examId).then(res => {
            if (res.status == 200) {
                setExamLanguages(res.data.data)
                setSelectedLanguage(res.data.data[0])
            }
        })
    }

    useEffect(() => {
        getQuestionByQuestionId();
        getExamLanguages();
    }, [])


    return (
        <>
            <Container className="border rounded p-3" style={{ width: "50%" }}>
                <Form >

                    {/* <Form.Group className="mb-3">
                        <Form.Label>Type</Form.Label>
                           <Form.Control 
                           type="text" 
                           placeholder={"Enter the type of statement here"}
                           value={type}
                           name="type"
                           onChange={(e) => setType(e.target.value)}
                           />
                    </Form.Group> */}

                    <Form.Group className="mb-3">
                        <Form.Label>Language</Form.Label>
                        <Form.Select
                            value={selectedLanguage}
                            name="type"
                            onChange={(e) => setSelectedLanguage(e.target.value)}
                        >
                            {
                                examLanguages.map((type: string, index: number) => {
                                    return <option value={type}>{type.toUpperCase()}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Question Statement</Form.Label>
                        <textarea
                            className="form-control"
                            placeholder={"Enter the statement here"}
                            value={statement}
                            name="statement"
                            style={{ height: "150px" }}
                            onChange={(e) => setStatement(e.target.value)}
                        ></textarea>
                    </Form.Group>

                    <Button type="submit" onClick={uploadHandler}>Add</Button>
                </Form>
            </Container>
            <Container>
                <div className="mt-4">
                    <h5>Uploaded Statement Data</h5>
                    <div>
                        {questionData && questionData.length > 0 ?
                            questionData.map((data: any) => {
                                return <StatementValueShow value={data} reload={getQuestionByQuestionId} />
                            }) : <span>No Statement Uploaded</span>}
                    </div>
                </div>
            </Container>
        </>

    )
}

export default QuestionStatement