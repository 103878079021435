import { faDownload, faEdit, faEye, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DicomViewModal from 'components/Dicom/DicomViewModal'
import Toolbar from 'components/Toolbar/Toolbar'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { ExamServices } from 'Services/examServices'

interface IQuestionDicomTable {
    data: any
    reload: any
    questionId: string
}

const QuestionDicomTable = ({ data, reload, questionId }: IQuestionDicomTable) => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [s3Link, setS3Link] = useState<string>();
    const [selectedDicomId, setSelectedDicomId] = useState<string>();



    function downloadURI(uri: string, name: string) {
        let link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

    const deleteQuestionDicomByQuestionDataId = async (questionDataId: any) => {
        await ExamServices.deleteQuestionDicomByQuestionDataId(data._id, questionDataId, questionId).then(res => {
            if (res.status === 200) {
                toast.success("Deleted")
                reload();
            }
        }).catch(e => {
            console.error(e);
            toast.error("something went wrong");
        })
    }

    const getS3Link = async() => {
        await ExamServices.getS3Link().then((res) => {
            if(res.status === 200){
                setS3Link(res.data.s3Url);
            }
        })
    }
    
    async function getCache(link: string) {
        const body = await fetch(link, { cache: "force-cache" })
        downloadURI(await body.text(), "file-data.zip");
    }

    useEffect(() =>{
        getS3Link();
    },[])

    return (
        <>
            <h5>Language:- {data.language.toUpperCase()}</h5>
            <DicomViewModal
                show={showModal}
                setShow={setShowModal}
                s3Link={s3Link}
                dicomId={selectedDicomId}
            />
            <Table striped borderless className="xrg-table" size="sm" responsive="md">
                <thead>
                    <tr>
                        <th>Question id</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.questionData.map((question: any) => {
                        return (
                            <>
                                <tr>
                                    <td>{question}</td>
                                    <td>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <Toolbar
                                                values={
                                                    [
                                                        {
                                                            value: <>
                                                                <FontAwesomeIcon icon={faEye} size="sm" className="me-2" />
                                                                View Dicom
                                                            </>,
                                                            onClick: () => {
                                                                setSelectedDicomId(question)
                                                                setShowModal(true)
                                                            },
                                                            variant: "secondary"
                                                        },
                                                        {
                                                            value: <>
                                                                <FontAwesomeIcon icon={faDownload} size="sm" className="me-2" />
                                                                Download Dicom
                                                            </>,
                                                            onClick: async() => {
                                                                await getCache(s3Link+"/"+question)
                                                            },
                                                            variant: "success"
                                                        },

                                                        {
                                                            value: <>
                                                                <FontAwesomeIcon icon={faMinusSquare} size="sm" className="me-2" />
                                                                Delete
                                                            </>,
                                                            onClick: () => deleteQuestionDicomByQuestionDataId(question),
                                                            variant: "danger"
                                                        }
                                                    ]
                                                }
                                                size="sm"
                                            />
                                        </div>
                                    </td>
                                </tr>

                            </>
                        )
                    })}

                </tbody>
            </Table>
        </>
    )
}

export default QuestionDicomTable