import { faTimesCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import makeRequest from 'api/makeRequest';
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { ExamServices } from 'Services/examServices';
import { ResponsesServices } from 'Services/responsesServices';


interface IViewResponsesModal {
    show: boolean;
    setShow: any;
    mappingId: any
}

export default function ViewResponsesModal(props: IViewResponsesModal) {

    const [userReponse, setUserResponse] = useState<any>([]);
    const [quesStatement, setQuesStatement] = useState<any>([]);

    const getUserResponsesByMappingId = async() => {
        await ResponsesServices.getUserResponseByMappingId(props.mappingId).then(res=>{
            if(res.status === 200){
                console.log(res.data);
                setUserResponse(res.data)
                for(let obj of res.data){
                    getQuestionStatement(obj.questionId).then(result=>console.log("I AM RESULT"))
                }
            }
        }).catch(e=>{
            console.error(e);
        })
    }

    const getQuestionStatement = async(qId:string) => {
        await ExamServices.getQuestionById(qId).then(res=>{
            if(res.status === 200){
                setQuesStatement([...quesStatement,
                    {
                        quetionId:qId,
                        statement:res.data.data.statement?res.data.data.statement[0].question:""
                    }
                ]);
            }
        }).catch(e=>{
            console.error(e);
        })
    }

    useEffect(()=>{
        getUserResponsesByMappingId();
    },[props.mappingId])


    return (
        <>
            <Modal
                show={props.show}
                size="xl"
            >
                <Modal.Body>
                   <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h6 className="text-primary fw-bold">
                                Responses
                            </h6>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faTimesCircle} className="text-secondary cursor-pointer" onClick={()=>props.setShow(false)}/>
                        </div>
                   </div>
                            <Table striped bordered hover className="my-3 w-100">
                                <thead>
                                    <tr>
                                        <th>Question ID</th>
                                        <th>Question Statement</th>
                                        <th>option value</th>
                                        <th>user answer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   {userReponse ? userReponse.map((response:any,index:number)=><tr>
                                    <td>{response.questionId}</td>
                                    <td></td>
                                    <td>{response.selectedOption.length>0 ? response.selectedOption[0].value : ""}</td>
                                    <td>{response.selectedOption.length>0 ? response.selectedOption[0].optionId : ""}</td>
                                   </tr>) : "NO USER RESPONSES"}
                                </tbody>
                            </Table>
                </Modal.Body>
            </Modal>
        </>
    )
}