import Select from 'react-select';
import React, { useEffect, useState } from 'react'
import { Container, Form, Col, Row, Button } from 'react-bootstrap';
import { ExamServices } from 'Services/examServices';



export interface ISection {
    name: string,
    duration: number
}

interface ISections {
    sectionData: any,
    handleChange: any,
    sectionCount: number,
    removeSection: any,
    layoutOption: any
}

const Sections = (props: ISections) => {
    return (
        <div>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control className='mt-2' type="text" value={props.sectionData.name} placeholder="Section Name" name="name" onChange={props.handleChange} />
                <Form.Control className='mt-2' type="number" placeholder="Section Duration (in mins)" value={props.sectionData.duration} name="duration" onChange={props.handleChange} />
                <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Choose Layout</Form.Label>
                            <Select
                                // defaultValue={[options[0]]}
                                name="layout"
                                options={props.layoutOption}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e: any) => {
                                    const event = {
                                        target: {
                                            value: e.value, 
                                            name: "layout"
                                        }
                                    }
                                    props.handleChange(event)
                                }}
                                value = {props.sectionData.layout ? {
                                    value: props.sectionData.layout,
                                    label: props.sectionData.layout
                                } : {}}
                            />
                        </Form.Group>                <Row className="mt-3">
                    <Button variant="danger" style={{ width: "5rem", marginLeft: "15px", border: "none" }} onClick={props.removeSection}>Delete</Button>
                </Row>
            </Form.Group>
        </div>
    )
}

export default Sections