import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Form } from 'react-bootstrap';

interface IHeaderTable {
    onDownloadAllClick: any
    onPageCountChange: any
}

export default function HeaderTable(props: IHeaderTable) {
    return (
        <>

            <div className="d-flex flex-row-reverse">
                <div className="d-flex justify-content-end align-items-center">
                    {/* <div className='col-auto me-2'>
                        <Form>
                            <Form.Select defaultValue='10'
                                style={{ width: 'auto' }}
                                onChange={(e) => { props.onPageCountChange(e.target.value) }}>
                                <option value="20">20 per page</option>
                                <option value="50">50 per page</option>
                                <option value="100">100 per page</option>
                            </Form.Select>
                        </Form>
                    </div> */}
                    {<div className="col-auto me-2">
                        <Button className='btn btn-warning' onClick={props.onDownloadAllClick} size="sm">
                            Download User Data
                            <FontAwesomeIcon icon={faDownload} className="ms-1" size="sm"/>
                        </Button>
                    </div>}
                </div>
            </div>
        </>
    )
}