import React, { createContext, useContext, useState } from "react";
import LoaderAnimation from "./Loader.animation";


interface ILoadingContext {
    loading: boolean
    setLoading: any
}

const LoadingContext = createContext<ILoadingContext>({
    loading: false,
    setLoading: null
});

export function Loader({ children }: any) {
    const [loading, setLoading] = useState(false);
    const value = { loading, setLoading };
    return (
        <LoadingContext.Provider value={value}>
            {
                loading ? <div className="dcm-loader bg-white bg-opacity-50">
                    <div className="dcm-loader-icon">
                        <LoaderAnimation/>
                        <h6 className="fw-bold dcm-loader-text mt-2">Loading</h6>
                    </div>

                </div> : <> </>
            }

            {children}
        </LoadingContext.Provider>
    );
}

export function useLoader() {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoader must be used within Loaderr");
    }
    return context;
}