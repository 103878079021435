import makeRequest, { makeParams } from '../api/makeRequest';
import makeUploadRequest from '../api/uploadRequest';
import url from '../api/urls';
import { RequstMethods } from '../api/requestMethode';

export class ExamServices {
  static async addChangeMappingTime(
    inputpayload: { examId: string; userId: string; time: number },
    mappingId: string,
  ) {
    return await makeRequest(
      url.admin.setTimeMapping + '/' + mappingId,
      RequstMethods.POST,
      inputpayload,
    );
  }

  static async getAllExams() {
    return await makeRequest(url.exam.getAllExams, RequstMethods.GET);
  }

  static async createExam(inputpayload: any) {
    return await makeRequest(
      url.exam.crateExam,
      RequstMethods.POST,
      inputpayload,
    );
  }

  static async deleteOneExam(_id: string) {
    return await makeRequest(
      url.exam.deleteOneExam + '/' + _id,
      RequstMethods.DELETE,
    );
  }

  static async updateExam(inputpayload: any, _id: string) {
    return await makeRequest(
      url.exam.updateExam + '/' + _id,
      RequstMethods.PUT,
      inputpayload,
    );
  }

  static async getExamById(_id: any) {
    return await makeRequest(
      url.exam.getExamById + '/' + _id,
      RequstMethods.GET,
    );
  }

  static async getExamStatus(_id: any) {
    return await makeRequest(
      url.exam.getExamStatus + '/' + _id,
      RequstMethods.GET,
    );
  }

  static async updateExamStatus(inputpayload: any, _id: any) {
    return await makeRequest(
      url.exam.updateExamStatus + '/' + _id,
      RequstMethods.PUT,
      inputpayload,
    );
  }

  static async getQuestionsByExamId(_id: string) {
    return await makeRequest(
      url.admin.getQuestionsByExamId + '/' + _id,
      RequstMethods.GET,
    );
  }
  static async getQuestionById(_id: string) {
    return await makeRequest(
      url.admin.getQuestionById + '/' + _id,
      RequstMethods.GET,
    );
  }

  static async addQuestionToExam(
    examId: string,
    sectionId: string,
    inputpayload: any,
  ) {
    return await makeRequest(
      url.admin.addQuestionToExam + '/' + examId + '/' + sectionId,
      RequstMethods.POST,
      inputpayload,
    );
  }


  static async duplicateQuestion(
    questionId: any
  ) {
    return await makeRequest(
      url.admin.duplicateQuestion + '/' + questionId,
      RequstMethods.POST,
      {},
    );
  }


  static async addAnswerToQuestion(id: string, inputpayload: any) {
    return await makeRequest(
      url.admin.addAnswerToQuestion + '/' + id,
      RequstMethods.POST,
      inputpayload,
    );
  }

  static async addQuestionToQuestion(id: string, payload: any) {
    return await makeUploadRequest(
      url.admin.addQuestionToQuestionById + '/' + id,
      RequstMethods.POST,
      payload,
    );
  }

  static async getQuestionTypes() {
    return await makeRequest(url.pulic.getQuestionType, RequstMethods.GET);
  }

  static async getOptionType() {
    return await makeRequest(url.pulic.getOptionType, RequstMethods.GET);
  }

  static async makeExamLive(id: string) {
    return await makeRequest(
      url.admin.makeExamive + '/' + id,
      RequstMethods.GET,
    );
  }

  static async getExamSections(id: string) {
    return await makeRequest(
      url.admin.getExamsections + '/' + id,
      RequstMethods.GET,
    );
  }

  static async addOptionToQuestion(questionId: string, payload: any) {
    return await makeUploadRequest(
      url.admin.addOptionToQuestion + '/' + questionId,
      RequstMethods.POST,
      payload,
    );
  }

  // static async deleteQuestionFromQuestionId(questionid: string, valueId: string, type: any) {
  //     return await makeRequest(url.admin.deleteQuestionFromQuestionId + '/' + questionid + '/' + valueId, RequstMethods.DELETE, type);
  // }

  static async uploadCandidateData(examId: any, payload: any) {
    return await makeUploadRequest(
      url.exam.uploadCandidateData + '/' + examId,
      RequstMethods.POST,
      payload,
    );
  }


  static async uploadZipUserData(examId: any, payload: any) {
    return await makeUploadRequest(
      url.exam.uploadZipUserData + '/' + examId,
      RequstMethods.POST,
      payload,
    );
  }
  static async getAllMappedUser(
    examId: any,
    pageCount?: any,
    pageNumber?: any,
  ) {
    const params = makeParams([
      {
        index: 'count',
        value: pageCount,
      },
      {
        index: 'page',
        value: pageNumber,
      },
    ]);

    return await makeRequest(
      url.exam.getAllMappedUser + '/' + examId + params,
      RequstMethods.GET,
    );
  }

  static async deleteMappingForExam(examId: string) {
    return await makeRequest(
      url.exam.deleteMappingForExam + '/' + examId,
      RequstMethods.DELETE,
    );
  }

  static async getQuestionDataTypes() {
    return await makeRequest(url.pulic.getQuestionDataTypes, RequstMethods.GET);
  }

  static async getAllLanguages() {
    return await makeRequest(url.pulic.getAllLanguages, RequstMethods.GET);
  }

  static async getExamLanguage(examId: any) {
    return await makeRequest(
      url.exam.getExamLanguage + '/' + examId,
      RequstMethods.GET,
    );
  }

  static async userAllowedStartTime(examId: string, payload: any) {
    return await makeRequest(
      url.admin.userAllowedStartTime + '/' + examId,
      RequstMethods.PUT,
      payload,
    );
  }

  static async getQuestionsByExamIdSectionId(
    examId: string,
    sectionId: string,
  ) {
    return await makeRequest(
      url.admin.getQuestionDataByExamIdAndSectionId +
      '/' +
      examId +
      '/' +
      sectionId,
      RequstMethods.GET,
    );
  }

  static async deleteQuestionFromQuestionById(
    questionId: string,
    id: string,
    type: any,
  ) {
    return await makeRequest(
      url.admin.deleteQuestionFromQuestion + '/' + questionId + '/' + id,
      RequstMethods.DELETE,
      type,
    );
  }

  static async deleteOptionById(questionId: string, optionId: string) {
    return await makeRequest(
      url.admin.deleteOptionById + '/' + questionId + '/' + optionId,
      RequstMethods.DELETE,
    );
  }

  static async deleteQuestionById(questionId: string) {
    return await makeRequest(
      url.admin.deleteQuestionById + '/' + questionId,
      RequstMethods.DELETE,
    );
  }

  static async setUserAllowedTime(
    examId: string,
    userAllowedStartTime: string,
    userAllowedEndTime: string,
  ) {
    return await makeRequest(
      url.exam.setUserAllowedTime + '/' + examId,
      RequstMethods.PUT,
      {
        userAllowedStartTime,
        userAllowedEndTime,
      },
    );
  }

  static async setExamDateAndTime(
    examId: string,
    startTime: string,
    endTime: string,
  ) {
    return await makeRequest(
      url.exam.setExamDateAndTime + '/' + examId,
      RequstMethods.PUT,
      {
        startTime,
        endTime,
      },
    );
  }
  static async setUserAllowedTillTime(examId: string, time: string) {
    return await makeRequest(
      url.exam.setuserAllowedTillTime + '/' + examId,
      RequstMethods.PUT,
      {
        time,
      },
    );
  }

  static async getUserAllowedExamTime(examId: string) {
    return await makeRequest(
      url.exam.getUserAllowedExamTime + '/' + examId,
      RequstMethods.GET,
    );
  }

  static async deleteStatementById(questionId: any, statementId: any) {
    return await makeRequest(
      url.admin.deleteStatementById + '/' + questionId + '/' + statementId,
      RequstMethods.DELETE,
    );
  }

  static async markExamCompleted(examId: any) {
    return await makeRequest(
      url.exam.markExamCompleted + '/' + examId,
      RequstMethods.GET,
    );
  }

  static async getAllUsers(examId: any) {
    return await makeRequest(
      url.exam.getAllMappedUser + '/' + examId,
      RequstMethods.GET,
    );
  }

  static async toggleUserActive(id: any) {
    return await makeRequest(
      url.admin.toggleUserActive + '/' + id,
      RequstMethods.PUT,
    );
  }

  static async resetMappingTime(mappingId: string, time: number, examId: string) {
    return await makeRequest(url.admin.resetMappingTime + "/" + mappingId, RequstMethods.POST, {
      time,
      examId
    });
  }

  static async deleteQuestionDicomByQuestionDataId(questionDataId: string, dataId: string, questionId: string) {
    return await makeRequest(url.admin.deleteQuestionDicomByQuestionDataId + "/" + questionId + "/" + questionDataId + "/" + dataId, RequstMethods.DELETE);
  }

  static async deleteQuestionImageByQuestionDataId(questionDataId: string, dataId: string, questionId: string) {
    return await makeRequest(url.admin.deleteQuestionImageByQuestionDataId + "/" + questionId + "/" + questionDataId + "/" + dataId, RequstMethods.DELETE);
  }


  static async getS3Link() {
    return await makeRequest(url.admin.getS3Link, RequstMethods.GET)
  }

  static async getDicomSecret(id: string) {
    return await makeRequest(url.admin.getDecriptionKeys + "/" + id, RequstMethods.GET)
  }

  static async moveQuestionSection(questionId: string, sectionId: string) {
    return await makeRequest(url.admin.questionMoveQuestionId + "/" + questionId , RequstMethods.PUT, {sectionId : sectionId})
  } 

  static async getUserLog(examId: any, userId: any) {
    return await makeRequest(url.admin.user_logs + "/" + examId + "/" + userId, RequstMethods.GET)
  }

  static async getLayout() {
    return await makeRequest(
      url.exam.getExamLayout,
      RequstMethods.GET,
    );
  }

  static async getQuestionData(questionId:string){
    return await makeRequest(url.exam.getQuestionData + "/" + questionId, RequstMethods.GET);
  }
}
