import React, { useEffect, useState } from 'react'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SliderModal from '../../../../components/Modals/Slider.modal'
import Toolbar from '../../../../components/Toolbar/Toolbar'
import AddOption from '../../../../components/Question/AddOption'
import { ExamServices } from '../../../../Services/examServices'
import { useParams } from 'react-router-dom'
import { errorResponseHelper } from '../../../../helpers/request.helper'
import OptionValueShow from '../../../../components/Question/OptionValueShow'
import CardBasic from '../../../../components/Cards/Card.basic'

const Index = () => {


  const params: any = useParams();
  const [questionData, setQuestionData] = useState<any>();
  const [showOption, setShowOption] = useState<boolean>(false);

  const getQuestionDetails = async () => {
    await ExamServices.getQuestionById(params.questionId).then(res => {
      if (res.status === 200) {
        console.log(res.data.data)
        setQuestionData(res.data.data);
      }
    }).catch(e => {
      errorResponseHelper(e)
    })

  }

  useEffect(() => {
    getQuestionDetails();
  }, [])

  return (
    <>
      <SliderModal
        show={showOption}
        setShow={setShowOption}
        body={
          <>
            <AddOption closeMenu={() => setShowOption(false)} reload={getQuestionDetails} />
          </>
        }
        title="Add Option to Question"
      />
      <div className='mt-4 d-flex justify-content-end'>
        <Toolbar
          values={
            [
              {
                value: <>
                  <FontAwesomeIcon icon={faPlusSquare} size="sm" className="me-2" />
                  Add Option to Question
                </>,
                onClick: () => { setShowOption(true) },
                variant: "primary"
              }
            ]
          }
          size="sm"
        />
      </div>
      <div>
        {questionData && questionData.options && questionData.options.length > 0 ? questionData.options.map((data: any, index: number) => {
          return (
            <CardBasic body={
              <OptionValueShow value={data} reload={getQuestionDetails} />
            }
              cardClass="mt-3"
            />
          )
        }) : "No Options Uploaded"}
      </div>
    </>
  )
}

export default Index