import { faArrowsAlt, faCopy, faEdit, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BooleanStatus from 'components/Badge/BooleanStatus'
import ChangeSectionModal from 'components/Modals/ChangeSectionModal'
import Toolbar from 'components/Toolbar/Toolbar'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Dropdown, DropdownButton, Modal, Table } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ExamServices } from 'Services/examServices'
import { LogService } from 'Services/logService'
interface IQuestionTable {
    tableData: any,
    reload: any
    questionNum: any
    sections: any[]
}





const QuestionTable = ({ tableData, questionNum, reload, sections }: IQuestionTable) => {

    const navigate = useNavigate();

    const [moveQuestionId, setMoveQuestionId] = React.useState<any>(undefined);


    const formatDate = (date: any) => {
        return ((moment(date)).format('DD-MMM-YYYY HH:mm'))
    }



    const deleteQuestion = async () => {
        await ExamServices.deleteQuestionById(tableData._id).then((res) => {
            if (res.status === 200) {
                toast.success("Question deleted successfully");
                reload();
            }
        }).catch((err) => {
            toast.error(err + "Unable to delete");
        })
    }

    const duplicateQuestion = async (question: string) => {
        await ExamServices.duplicateQuestion(question).then((res) => {
            if (res.status === 200) {
                toast.success("Question Duplicated successfully with id:  " + res.data.data._id);
                reload();
            }
        }).catch((err) => {
            toast.error(err + "Unable to duplicate");
        })
    }

    const moveQuestion = (questionId: string) => {
        setMoveQuestionId(questionId);
    }

    const onConfirmMove = async (sectionId: string) => {
        await ExamServices.moveQuestionSection(moveQuestionId, sectionId).then((res) => {
            if (res.status === 200) {
                toast.success("Question moved successfully");
                setMoveQuestionId(undefined);
                reload();
            }
        }).catch((err) => {
            toast.error("Unable to move question");
            console.error(err)
        })
    }



    return (
        <tr>
            <ChangeSectionModal
                setHide={() => setMoveQuestionId(undefined)}
                questionId={moveQuestionId}
                sections={sections}
                onConfirmMove={(sectionId: string) => onConfirmMove(sectionId)}
            />
            <td>{questionNum}</td>
            <td>{tableData._id}</td>
            <td>{tableData && tableData.questionType.toUpperCase()}</td>
            <td><BooleanStatus
                status={tableData.value && tableData.value.length > 0 ? true : false}
                values={{
                    true: "Uploaded",
                    false: "Pending"
                }}
            /></td>
            <td><BooleanStatus
                status={tableData.options && tableData.options.length > 0 ? true : false}
                values={{
                    true: "Uploaded",
                    false: "Pending"
                }}
            /></td>
            <td><BooleanStatus
                status={tableData.image && tableData.image.length > 0 ? true : false}
                values={{
                    true: "Uploaded",
                    false: "Pending"
                }}
            /></td>
            <td><BooleanStatus
                status={tableData.statement && tableData.statement.length > 0 ? true : false}
                values={{
                    true: "Uploaded",
                    false: "Pending"
                }}
            /></td>
            <td>{formatDate(tableData.createdAt)}</td>
            <td>
                <div className="d-flex justify-content-between align-items-center">
                    <DropdownButton id="dropdown-basic-button" title="Actions">
                        <Dropdown.Item onClick={() => deleteQuestion()}><FontAwesomeIcon icon={faMinusSquare} size="sm" className="me-2" /> Delete</Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate('/admin-dash/exam/' + tableData.examId + '/questions/' + tableData._id)}> <FontAwesomeIcon icon={faEdit} size="sm" className="me-2" />
                            View/Edit</Dropdown.Item>
                        <Dropdown.Item onClick={() => duplicateQuestion(tableData._id)}> <FontAwesomeIcon icon={faCopy} size="sm" className="me-2" />
                            Duplicate Question</Dropdown.Item>

                        <Dropdown.Item onClick={() => moveQuestion(tableData._id)}> <FontAwesomeIcon icon={faArrowsAlt} size="sm" className="me-2" />
                            Move Question</Dropdown.Item>
                    </DropdownButton>
                </div>
            </td>
        </tr>
    )
}

export default QuestionTable