import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableFooter from '../../../../components/Tables/Footer.table';
import HeaderTable from '../../../../components/Tables/Header.table';
import UsersTable from '../../../../components/Tables/UsersTable';
import { ExamServices } from '../../../../Services/examServices';
import TableRows from './TableRows';

import { CSVDownload } from 'react-csv';
import moment from 'moment';
interface IUsersTable {
  pageCount: any;
  setPageCount: any;
  userData: any;
  pageNumber: any;
  setPageNumber: any;
  totalPages: any;
  reloadData: any;
}
export default function ExamUserTable({
  pageCount,
  setPageCount,
  pageNumber,
  setPageNumber,
  userData,
  totalPages,
  reloadData,
}: IUsersTable) {
  const params: any = useParams();
  const [downloadData, setDownloadData] = useState<any>();

  const onDownloadClick = async () => {
    await ExamServices.getAllMappedUser(params.examId)
      .then((res) => {
        if (res.status === 200) {
          setDownloadData(
            res.data.data.users.map((data: any) => {
              return {
                'Mapping Id': data._id,
                Email: data.student.email,
                Name: data.student && data.student.name,
                'Last Name': data.student.last_name,
                Gender: data.student.gender,
                DOB: moment(data.student && data.student.dob).format('DD-MM-YYYY'),
                Mobile: data.student.mobile,
                'Student Id': data.student._id,
                'Exam Status': data.examStatus,
                'Registration Number': data?.student?.registrationNumber,
              };
            }),
          );
        }
      })
      .catch((err) => {
        toast.error('Unable to fetch User data');
      });
  };

  return (
    <>
      <HeaderTable
        onDownloadAllClick={onDownloadClick}
        onPageCountChange={setPageCount}
      />
      {downloadData && downloadData.length > 0 ? (
        <CSVDownload data={downloadData} />
      ) : (
        ' '
      )}
      <div className="mt-2">
        <UsersTable
          tableData={userData}
          tableRows={<TableRows data={userData} reloadData={reloadData} />}
          pageNumber={pageNumber}
          pageCount={pageCount}
        />
      </div>
      {/* <TableFooter
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        totalPages={totalPages}
      /> */}
    </>
  );
}
