import React from 'react';
import { Badge } from 'react-bootstrap';

interface IBooleanStatus {
    status: boolean
    values: {
        true: any
        false: any
    }
}

export default function BooleanStatus (props: IBooleanStatus) {
    return (
        <Badge bg={props.status ? "success" : "danger"}>
            {props.status ? props.values.true : props.values.false}
        </Badge>
    )
}