import React from 'react'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dropzone from 'react-dropzone'
import './dropzone.css'

interface ICandidateData {
    handleUploadedFile: any
}


const CandidateDataDropzone = ({handleUploadedFile}:ICandidateData) => {
    return (
        <>
            <Dropzone onDrop={handleUploadedFile}>
                {({ getRootProps, getInputProps }: any) => (
                    <section>
                        <div className="xcn-dropzone">
                            <div {...getRootProps()}>
                                <input type = "file" accept="text/csv" {...getInputProps()} />
                                <FontAwesomeIcon icon={faFileUpload} className="xcn-text-xl text-secondary" />
                                <p className="mt-5">Drag 'n' drop some Candidate Data here, or click to Select</p>
                            </div>
                        </div>

                    </section>
                )}
            </Dropzone>
        </>
    )
}

export const CandidateZipDataDropzone = ({handleUploadedFile}:ICandidateData) => {
    return (
        <>
            <Dropzone onDrop={handleUploadedFile}>
                {({ getRootProps, getInputProps }: any) => (
                    <section>
                        <div className="xcn-dropzone">
                            <div {...getRootProps()}>
                                <input type = "file" accept="text/csv" {...getInputProps()} />
                                <FontAwesomeIcon icon={faFileUpload} className="xcn-text-xl text-secondary" />
                                <p className="mt-5">Drag 'n' drop some Zipped Data here, or click to Select</p>
                            </div>
                        </div>

                    </section>
                )}
            </Dropzone>
        </>
    )
}


export default CandidateDataDropzone