import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface IPromptModal {
    show: boolean;
    setShow: any;
    body: any;
    submitButton?: any;
}

export default function PromptModal(props: IPromptModal) {
    return (
        <>
            <Modal
                centered
                show={props.show}
            >
                <Modal.Body>
                    <div>
                        {props.body}
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                        <Button variant="secondary" onClick={() => props.setShow(false)}>Close <FontAwesomeIcon icon={faXmark} className="ms-1" /> </Button>
                        {props.submitButton && <div className="ms-2"> {props.submitButton} </div>}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}