import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from 'components/Loader/Loader';
import MarksModal from 'components/Modals/Marks.modal';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ExamServices } from 'Services/examServices';
import { ResultSerivces } from 'Services/resultService';
import QuestionCard from './QuestionCard'

const Result = () => {
    const { examId }: any = useParams();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [questionData, setQuestionData] = useState<any>();
    const [answers, setAnswers] = useState([]);
    const [marking, setMarking] = useState<any>({
        marksForCorrectOption: "",
        marksForIncorrectOption: "0",
        marksWhenIsDeletedTrue: ""
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>();


    // let header = ["_id", "registrationNumber", "centerId", "center_name", "city", "email", "email", "name", "marks", "marksForCorrectOption", "marksForDeletedQuestion", "correctQuestions"]

  
   


    const getAllQuestion = async () => {
        await ExamServices.getQuestionsByExamId(examId).then((res) => {
            if (res.status === 200) {
                setQuestionData(res.data.data);
            }
        }).catch(err => {
            toast.error(err);
        })
    }


    useEffect(() => {
        getAllQuestion();
    }, [])

    const getAllAnswersByExamId = async () => {
        await ResultSerivces.getAllAnswersByExamId(examId).then((res) => {
            if (res.status === 200) {
                setAnswers(res.data);
            }
        }).catch(err => {
            toast.error(err);
        })
    }

    useEffect(() => {
        getAllAnswersByExamId();
    }, [])


    return (
        <>
            <Card className="mt-5 shadow p-3 mb-5 bg-white rounded">
                <Card.Body>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3>Questions List</h3>
                        <Button variant="secondary" onClick={() => setShowModal(true)}>Download</Button>
                    </div>
                    <div className='mt-3'>
                        <Alert> 
                            <b>Warning! <FontAwesomeIcon icon={faTriangleExclamation}/> Read the Instructions carefully before proceeding:</b>
                            <p>
                                <ul>
                                    <li><b>This functionality is only for MCQ type and True/False Type Questions</b></li>
                                    <li>Mark correct option for each question.</li>
                                    <li>There can be multiple correct options for <b> MCQ </b> type question</li>
                                    <li>If question has to be removed from final marks calculation mark <b>Delete</b> as <b>'YES'</b></li>
                                    <li>For True/False Type question if option for the question is true mark the checkbox as checked, if its false mark it unchecked.</li>
                                    <li>Once marking is complete, click Download to open download dialoge where you can enter marks for each correct option, marking works as below 
                                        <ul>
                                            <li>
                                                <b>Marks for correct option: </b> Marks to be awarded for each correct option to the user.
                                            </li>
                                            <li>
                                                <b>Marks for deleted Question: </b> Marks to be added for each deleted question.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Once Successfully generated final result can be downloaded in .csv format
                                    </li>
                                </ul>
                                </p>
                        </Alert>
                    </div>
                    <div className="mt-3">
                        <QuestionCard
                            setAnswers={setAnswers}
                            data={questionData}
                            reloadAnswers={getAllAnswersByExamId}
                            examId={examId}
                            answers={answers}
                        />

                    </div>
                </Card.Body>
            </Card>
            {loading === true ? <p>Loading</p> :
                <MarksModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    examId={examId}
                />
            }
        </>
    )
}

export default Result