import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import SideBar from '../../components/SideBar/SideBar';
import TopBar from '../../components/TopBar/TopBar';
import adminRoutes, { baseUrl } from '../../routes/routes';


export default function PrivateRoutes() {

    return (
        <>
            <TopBar
            />
            <SideBar
                menuData={adminRoutes}
                panelName="Admin"
                baseUrl={baseUrl}
            />

            <Container>
                <Outlet />
            </Container>
        </>
    )
}