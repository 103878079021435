import PromptModal from 'components/Modals/Prompt.modal';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import Datetime from 'react-datetime';
import ReactDatePicker from 'react-datepicker';


interface Proptypes {
  show: boolean;
  setShow: any;
  type?: { type: string; label: string };
  onTimeChange?: (start: any, end: any) => void;
  onSubmit?: () => void;
  defaultValues?: {
    start?: string;
    end?: string;
  };
}
const TimeModal = ({
  show,
  setShow,
  type,
  onTimeChange,
  onSubmit,
  defaultValues,
}: Proptypes) => {
  const [start, setStart] = useState<any>(
    defaultValues?.start,
  );
  const [end, setEnd] = useState<any>(
    defaultValues?.end,
  );
  useEffect(() => {
    onTimeChange?.(start as any, end as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  return (
    <PromptModal
      setShow={setShow}
      show={show}
      submitButton={
        <Button variant="success" className="mt-1 ms-2" onClick={onSubmit}>
          Set
        </Button>
      }
      body={
        <Row>
          <Col>
            <p className="fw-bold">{type?.label}</p>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <div>
                <Form.Label className="fw-bold xcn-text-12 text-secondary">
                  {type?.type !== 'userTillTime' ? 'Start Time' : 'Set Time'}
                </Form.Label>
                <ReactDatePicker
                    selected={new Date(start)}
                    onChange={(value:any) => setStart(new Date(value))}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
              </div>

              {type?.type !== 'userTillTime' && (
                <div>
                  <Form.Label className="fw-bold xcn-text-12 text-secondary">
                    End Time
                  </Form.Label>
                  <ReactDatePicker
                    selected={new Date(end)}
                    onChange={(value:any) => setEnd(new Date(value))}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      }
    />
  );
};

export default TimeModal;
