import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import parse from 'html-react-parser';

interface IExamQuestionStatement {
    statement: any
    index: any
}

export default function ExamQuestionStatement(props: IExamQuestionStatement) {

    useEffect(()=>{
        if(props.statement && props.statement.question){
            const element = document.getElementById("dcm-statement-text-question-"+String(props.index));
            if(element){
                element.innerHTML = props.statement.question
            }
        }
    }, [props.statement])

    return (
        <>
            <div className='ms-2 p-2'>
                    <div className="mx-2">
                        <div className='pb-1'>
                        Question {String(props.index)}: <br/>
                        {parse(props.statement)}
                        </div>
                    </div>
            </div>
        </>
    )
}