import { faCheck, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Card, Dropdown, Table } from 'react-bootstrap'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ExamServices } from '../../../../Services/examServices'
import { ResponsesServices } from '../../../../Services/responsesServices'
// import  prettyMs  from 'pretty-ms';
import ViewResponsesModal from 'components/Modals/ViewResponsesModal'
import { ResultSerivces } from 'Services/resultService'
import { parse } from 'json2csv';

const download = require("downloadjs");

export interface State {
    mappingId: string
}

const ResponseTable = () => {

    const params: any = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const state = location.state as State;


    const [userData, setUserData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageCount, setPageCount] = useState(20);
    const [getResponses, setGetResponses] = useState<any>();
    const [mappedUser, setMappedUser] = useState<any>();

    const [selectedMappingId, setSelectedMappingId] = useState<any>(undefined);
    const [showResponsesModal, setShowResponsesModal] = useState<boolean>(false);

    const getAllCompletedUsersForExam = async () => {
        await ResponsesServices.getAllResponsesByExamId(params.examId).then((res) => {
            if (res.status === 200) {
                setUserData(res.data.data);
            }
        }).catch((err) => {
            toast.error("Unable to fetch response")
        })
    }
    const downloadExistingResultZip  = async () => {
        await ResultSerivces.downloadExistingResultZip(params.examId).then((res) => {
            if (res.status === 200) {
                
                download(res.data, "result.zip", "application/zip");
            }
        }).catch(e  => {
            toast.error(e.response.data)
            console.error(e)
        })
    }

    const downloadResult = async () => {
        toast.success("Sending Create Result Request: Check back in sometime")
        await ResultSerivces.createUserResultCSVByExamId(params.examId).then((res) => {
            if (res.status === 200) {
                download(res.data, "result.zip", "application/zip");

                // const fileURL = window.URL.createObjectURL(new Blob([res.data]));
                // const fileLink = document.createElement('a');
                // fileLink.href = fileURL;
                // const fileName = res.headers['content-disposition'].substring(22, 52);
                // fileLink.setAttribute('download', fileName);
                // fileLink.setAttribute('target', '_blank');
                // document.body.appendChild(fileLink);
                // fileLink.click();
                // fileLink.remove();
            }
        }).catch(e => {
            toast.error(e.response.data)
            console.error(e)
        })
    }

    const downloadUserResult = async (mappingId: string) => {
        await ResultSerivces.createUserResult(mappingId).then((res) => {
            if (res.status === 200) {
                download(res.data, "result.zip", "application/zip");
            }
        }).catch(e => {
            toast.error(e.response.data)
            console.error(e)
        })
    }


    const downloadLogData = async(userId:any) => {
        await ExamServices.getUserLog(params.examId, userId).then((res) => {
            if (res.status === 200) {
                const csv = parse(res.data.logs);
                // console.log(csv);
                // const blob = new Blob([csv], { type: 'text/csv' });
                // const fileURL = URL.createObjectURL(blob);
                // let alink = document.createElement('a');
                // alink.href = fileURL;
                // alink.download = 'results.csv';
                // alink.click();
                
                download(csv, "live_exam_log_"+userId+".csv", "text/csv");
            }
        }).catch(e => {
            toast.error(e.response.data)
            console.error(e)
        })
    }

    useEffect(() => {
        // getAllMappedUser();
        // getAllResponses();
        getAllCompletedUsersForExam();
    }, [])

    return (
        <div className="mt-2">

            {/* Modals */}

            <ViewResponsesModal
                show={showResponsesModal}
                setShow={setShowResponsesModal}
                mappingId={selectedMappingId}
            />

            {/* End Modals */}
            <div className="d-flex justify-content-between align-items-center">
                <h5 className='text-primary fw-bold my-4'>
                    User Result/Responses
                </h5>
                <div>
                    <Button variant='danger' size="sm" onClick={downloadResult}>
                        1. Create Result
                    </Button>
                    <Button variant='primary' className='ms-2' size="sm" onClick={downloadExistingResultZip}>
                        2. Download Result
                    </Button>
                </div>

            </div>
            <Card className="dcm-exam-list-card ">
                <Table striped>
                    <thead>
                        <tr>
                            <th>Sr.no</th>
                            <th>Registration Number</th>
                            <th>Candidate Details</th>
                            <th>Completion</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userData && userData.length > 0 ?
                            userData.map((responses: any, index: number) => {
                                return (<>
                                    <tr>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            {responses?.user?.registrationNumber}
                                        </td>
                                        <td className='xrg-text-12'>
                                            <img src={responses.user?.image} height="25px" className='me-2' />
                                            {responses.user?.name + " - " + responses.user?.email}
                                        </td>
                                        <td className='xrg-text-12'>
                                            {responses.mapping && responses.mapping.completionStatus ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : "---"}
                                        </td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="info" id="dropdown-basic">
                                                    Actions
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => downloadUserResult(responses.mapping._id)}>Download Result</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => downloadLogData(responses?.user?._id)}>Download Log Data</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {/* <FontAwesomeIcon icon={faEye} className="text-secondary cursor-pointer" size="sm" onClick={()=>{
                                            setSelectedMappingId(responses.mapping._id);
                                            setShowResponsesModal(true)
                                        }}/> */}

                                        </td>
                                    </tr>

                                </>)
                            })
                            :
                            "Exam yet to begin"
                        }
                        {/* {userData && userData.length > 0 ? userData.map((data: any, index: any) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{ data.student && data.student.name}</td>
                                <td>{data.student.email}</td>
                                <td><FontAwesomeIcon icon={faEye} onClick={() => navigate("/admin-dash/responses/" + params.examId  + "/" + data._id)} /></td>
                            </tr>
                        )
                    }) : "No data found"} */}
                    </tbody>
                </Table>
            </Card>
        </div>
    )
}

export default ResponseTable