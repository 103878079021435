import React, { useState } from 'react';
import { Nav, } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import './sidebar.css';
import IRouter from '../../interfaces/IRouter';
import { baseUrl } from '../../routes/routes';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';


interface ISideBar {
    menuData: IRouter[],
    panelName: string,
    baseUrl: string
}

export default function SideBar({ menuData, panelName, baseUrl }: ISideBar) {
    let navigate = useNavigate();
    const location = useLocation();

    const [close, setClose] = useState(true);


    const showAllowedMenu = menuData.filter((routes) => routes.navbarShow === true)

    return (
        <>
            <Nav defaultActiveKey="0" className={close ? "flex-column xrg-dashboard-sub-nav xrg-nav-closed" : "flex-column xrg-dashboard-sub-nav"} >
                <div className='mt-3'>
                    {/* <div className='d-flex justify-content-center mt-3'>
                        <FontAwesomeIcon icon={faBars} onClick={() => setClose(!close)} />
                    </div> */}
                </div>
                {
                    showAllowedMenu.map((data, index) => {
                        return (
                            <div key={index} 
                            className={"xrg-nav-link" + (data.path == location.pathname.split("/")[2] ? " xrg-nav-selected" : " ")} 
                            onClick={()=>navigate(baseUrl +"/"+ data.path)}
                            >
                                <div className='xrg-nav-item'>
                                    <div className={(close ? "xrg-nav-close" : "xrg-nav-open")}>
                                    <FontAwesomeIcon icon={data.icon} />
                                    { close ? <></> : data.name }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Nav>
        </>
    )
}