import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, FloatingLabel, Form, Button } from 'react-bootstrap'
import { AuthService } from '../../Services/authServices';
import Auth from '../../Auth/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Login() {
    const [loginData, setLoginData] = useState<any>({
        email: "",
        password: "",
    });

    const onChangeValue = (e: any) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };
    const navigate = useNavigate();

    const loginSubmit = async (e: any) => {
        e.preventDefault();
        await AuthService.login(({
            email: loginData.email,
            password: loginData.password
        }))
            .then(async (res) => {
                if (res.status === 200) {
                    await Auth.authenticate();
                    localStorage.setItem("authKey", res.data.token)
                    navigate('/admin-dash/exam')
                    toast("Login Success")
                }
            })
            .catch((err) => {
                if (err.response.status === 403) {
                    console.error(err);
                } else if (err.response.status === 500) {
                    alert(err.response.data)
                }
            })
    };


   

    return (
        <>
            <Container>
                <h1 className="text-center font-weight-bold xcn-login-header">
                    Admin Login
                </h1>
                <Row className="justify-content-md-center  mt-4">
                    <Col xs lg="2" />
                    <Col md="auto">
                        <Card className="p-3" style={{ maxWidth: "25rem", minWidth: "25vw" }}>
                            <>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email address"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder="name@example.com"
                                        name="email"
                                        onChange={onChangeValue}
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingPassword"
                                    label="Password"
                                    onChange={onChangeValue}
                                >
                                    <Form.Control type="password" placeholder="Password" name="password" />
                                </FloatingLabel>
                            </>
                            <Button
                                variant="primary"
                                type="submit"
                                className="mt-4"
                                size="lg"
                                onClick={loginSubmit}
                            >
                                <span className="xcn-text-14">
                                    Login
                                </span>
                            </Button>
                        </Card>
                    </Col>
                    <Col xs lg="2" />
                </Row>
            </Container>
        </>
    )
}