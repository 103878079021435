import React from 'react'

import "./Loader.animation.css";

export default function LoaderAnimation() {
    return (
        <div className="flipping">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}