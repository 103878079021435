import React, { useState, useEffect, useRef } from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import Hammer from 'hammerjs';
import dicomParser from 'dicom-parser';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import { Button, Spinner } from 'react-bootstrap';

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

interface IProps {
  onClose: () => void;
  links: any[];
  show: boolean;
}

const CornerStoneDicomViewer = ({ onClose, links, show }: IProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState([]);
  const [windowWidth, setWindowWidth] = useState(128);
  const [windowCenter, setWindowCenter] = useState(128);
  const [zoom, setZoom] = useState(0.75);
  const [activeTool, setActiveTool] = useState<string>("");
  const elementRef = useRef(null);
  const [loadingImages, setLoadingImages] = useState<boolean>(false);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    cornerstone.enable(element);

    cornerstoneTools.init({
      mouseEnabled: true,
      touchEnabled: true,
      globalToolSyncEnabled: true,
      showSVGCursors: true,
    });

    // cornerstoneTools.toolStyle.setToolWidth(5);
    cornerstoneTools.toolColors.setToolColor("red");

    cornerstoneTools.addTool(cornerstoneTools.PanTool);
    cornerstoneTools.addTool(cornerstoneTools.ZoomTool);
    cornerstoneTools.addTool(cornerstoneTools.WwwcTool);
    cornerstoneTools.addTool(cornerstoneTools.StackScrollMouseWheelTool);
    cornerstoneTools.addTool(cornerstoneTools.ZoomMouseWheelTool);
    cornerstoneTools.addTool(cornerstoneTools.MagnifyTool);
    cornerstoneTools.addTool(cornerstoneTools.RotateTool);
    cornerstoneTools.addTool(cornerstoneTools.LengthTool);
    cornerstoneTools.addTool(cornerstoneTools.AngleTool);
    // cornerstoneTools.addTool(cornerstoneTools.BrushTool);
    cornerstoneTools.addTool(cornerstoneTools.ProbeTool);
    cornerstoneTools.addTool(cornerstoneTools.RectangleRoiTool);
    cornerstoneTools.addTool(cornerstoneTools.EllipticalRoiTool);


    setupInitialTools();
  }, [imagesLoaded]);

  const stack = {
    currentImageIdIndex: currentImageIndex,
    imageIds: imagesLoaded
  }

  useEffect(() => {
    cornerstoneTools.addStackStateManager(elementRef.current, ['stack']);
    cornerstoneTools.addToolState(elementRef.current, 'stack', stack);
  }, [imagesLoaded]);

  const setupInitialTools = () => {
    // cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 3 });
    cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2 });
    cornerstoneTools.setToolActive('StackScrollMouseWheel', { mouseButtonMask: 0 });
  };

  function handleToolChange(tool: string) {
    console.log('tool changed', tool);
    // console.log(cornerstoneTools.isToolActiveForElement(elementRef.current, tool))

    Object.keys(tools).map((key) => {
      if (key !== tool && cornerstoneTools.isToolActiveForElement(elementRef.current, key)) {
        cornerstoneTools.setToolPassive(key);
      }
    })

    if (cornerstoneTools.isToolActiveForElement(elementRef.current, tool)) {
      cornerstoneTools.setToolPassive(tool);
    } else {
      cornerstoneTools.setToolActive(tool, { mouseButtonMask: 1 });
      setActiveTool(tool);
    }
  }

  function handleClear() {
    cornerstoneTools.clearToolState(elementRef.current, activeTool)
    cornerstone.updateImage(elementRef.current);
  }

  const handleScrollChange = (newIndex) => {
    setCurrentImageIndex(newIndex);
    loadAndDisplayImage(newIndex);
  };


  const handleFileChange = (event: any) => {
    const files = event.target.files;
    const imageIds: any = Array.from(files).map(file => cornerstoneWADOImageLoader.wadouri.fileManager.add(file));
    setImagesLoaded(imageIds);
    setCurrentImageIndex(0);
    loadAndDisplayImage(0);
  };

  const loadAndDisplayImage = async (index) => {
    const imageId = imagesLoaded[index];
    if (imageId) {
      await cornerstone.loadAndCacheImage(imageId)
        .then(image => {
          const viewport = cornerstone.getDefaultViewportForImage(elementRef.current, image);
          viewport.voi.windowWidth = windowWidth;
          viewport.voi.windowCenter = windowCenter;
          viewport.scale = zoom;
          cornerstone.displayImage(elementRef.current, image, viewport);
        })
        .catch(err => console.log(err, "Error loading image"))
        .finally(() => {
          setCurrentImageIndex(index);
        })
    }
  };

  const updateViewport = () => {
    try {
      const element = elementRef.current;
      const viewport = cornerstone.getViewport(element);
      viewport.voi.windowWidth = windowWidth;
      viewport.voi.windowCenter = windowCenter;
      viewport.scale = zoom;
      cornerstone.setViewport(element, viewport);
      cornerstone.updateImage(element);
    } catch (err) {
      console.error('Unhandled exception in updateViewport()', err);
    }
  };


  function loadFileFromLink() {
    try {
      const imageIds = [];

      const promises = links.map(link =>
        fetch(link)
          .then(response => response.arrayBuffer())
          .then(buffer => {
            const file = new Blob([buffer], { type: 'application/dicom' });
            const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
            imageIds.push(imageId);
          })
          .catch(err => {
            console.error(err, "error while loading image from link");
          })
      );

      setLoadingImages(true);
      Promise.all(promises)
        .then(() => {
          setImagesLoaded(imageIds);
          setCurrentImageIndex(0);
          loadAndDisplayImage(0);
        })
        .catch(err => {
          console.error(err, "error while loading images from links");
        })
        .finally(() => {
          setLoadingImages(false);
        })
    } catch (err) {
      console.error(err, "error while loading images from links");
    }
  }

  useEffect(() => {
    updateViewport();
  }, [windowWidth, windowCenter, zoom]);

  useEffect(() => {
    if (imagesLoaded.length > 0) {
      setCurrentImageIndex(0);
      loadAndDisplayImage(0);
    }
  }, [imagesLoaded]);

  useEffect(() => {
    if (show && links.length > 0) {
      loadFileFromLink();
    }
  }, [show]);


  return (
    <div className="mt-2">
      {
        !loadingImages ?
          <>
            <div className='d-flex align-items-center justify-content-center'>
              <Button size='sm' variant='warning' className='me-2' onClick={onClose}>Back</Button>
              {
                Object.keys(tools).map((key) => {
                  return <Button key={key} size='sm' className='me-2' variant={activeTool === key ? 'primary' : 'outline-secondary'} onClick={() => handleToolChange(key)}>{key}</Button>
                })
              }
              <Button size='sm' variant='secondary' className='me-2' onClick={handleClear}>Clear Annotations</Button>
              <Button size="sm" variant='secondary' className='me-2' onClick={() => cornerstoneTools.playClip(elementRef.current, 2)}>Play Clip</Button>
              <Button size='sm' variant='secondary' onClick={() => cornerstoneTools.stopClip(elementRef.current)}>Stop Clip</Button>
            </div>
            {/* <input type="file" onChange={handleFileChange} multiple accept=".dcm" /> */}
            <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              <div
                ref={elementRef}
                style={{ minWidth: '500px', minHeight: '500px', position: 'relative', color: 'white', border: '1px solid black', userSelect: 'none' }}
                onContextMenu={(e) => e.preventDefault()}
                className=''
              ></div>
            </div>
            <span className='fs-6 text-light'>
                Total Images: {imagesLoaded.length}
              </span>
            <div className="d-flex align-items-center justify-content-center mt-2 gap-2">
              <Button onClick={() => loadAndDisplayImage((currentImageIndex - 1 > 1 ? currentImageIndex - 1 : imagesLoaded.length - 1) % imagesLoaded.length)}>Previous Image</Button>
              <Button onClick={() => loadAndDisplayImage((currentImageIndex + 1) % imagesLoaded.length)}>Next Image</Button>
            </div>
          </>
          :
          <>
            <div className="d-flex align-items-center justify-content-center h-100">
              <Spinner animation={'border'} variant='primary' />
              <span className='fs-1 ms-2 text-white'>Loading Images</span>
            </div>
          </>
      }
    </div>
  );
};

export default CornerStoneDicomViewer;

const tools = {
  'Magnify': 'Magnify',
  "Wwwc": "Wwwc",
  "Rotate": "Rotate",
  "Length": "Length",
  "Angle": "Angle",
  // "Brush": "Brush",
  "Probe": "Probe",
  "Pan": "Pan",
  "RectangleRoi": "RectangleRoi",
  "EllipticalRoi": "EllipticalRoi",
}