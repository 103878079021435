import makeRequest, { makeParams } from "../api/makeRequest";
import makeUploadRequest from "../api/uploadRequest";
import url from "../api/urls";
import { RequstMethods } from "../api/requestMethode";

export class ResponsesServices { 
    static async getAllResponsesByExamId(examId: string) {
        return await makeRequest(url.admin.getAllResponsesByExamId + "/" + examId, RequstMethods.GET)
    }

    static async getUserResponseByMappingId(mappingId: any) {
        return await makeRequest(url.admin.getUserResponseByMappingId + "/" + mappingId, RequstMethods.GET);
    }

    static async getAllCompletedUsersForExam(examId: any) {
        return await makeRequest(url.admin.getAllCompletedUsersForExam + "/" + examId, RequstMethods.GET);
    }

}