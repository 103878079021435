import moment from 'moment';
import { Table } from 'react-bootstrap';

interface IUsersTable {
  tableData: any;
  tableRows?: any;
  pageNumber?: number;
  pageCount?: number;
}

const UsersTable = ({
  tableData,
  tableRows,
  pageNumber,
  pageCount,
}: IUsersTable) => {
  const formatDate = (date: any) => {
    return moment(date).format('DD-MMM-YYYY HH:mm');
  };

  return (
    <Table striped borderless className="xrg-table" size="sm" responsive="md">
      <thead>
        <tr>
          <th>Sr. No.</th>
          <th>Reg. No.</th>
          <th>Username</th>
          <th>DOB</th>
          <th>Online</th>
          <th>Center Id</th>
          <th>Name</th>
          <th>Email</th>
          <th>Completed</th>
          <th>Lock/Un-Lock</th>
          <th>Time Rem.</th>
          {/* <th>Created At</th>
          <th>Updated At</th> */}
        </tr>
      </thead>
      <tbody>{tableRows && tableRows}</tbody>
    </Table>
  );
};

export default UsersTable;
