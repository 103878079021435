import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Modal } from "react-bootstrap";

interface ISliderModal {
    show: boolean
    setShow: any
    body: any
    title: any
    onSave?: any
    saveBtn?: string
}

export default function SliderModal(props: ISliderModal) {
    return (
        <>
            <Modal show={props.show} onHide={() => props.setShow(false)}>
                <Modal.Body>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="mb-3">
                            {props.title}
                        </div>
                        <div className="cursor-pointer">
                            <FontAwesomeIcon icon={faXmark} onClick={() => props.setShow(false)} />
                        </div>
                    </div>
                    {props.body}
                    <div className="d-flex justify-content-end mt-3">
                        <Button variant="secondary" size="sm" className="me-2" onClick={() => props.setShow(false)}>
                            Close
                        </Button>
                        {
                            props.onSave && <Button variant="primary" size="sm" onClick={props.onSave}>
                                {props.saveBtn || "Save Changes"}
                            </Button>
                        }

                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}