/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Container, Badge, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CardBasic from '../../../components/Cards/Card.basic';
import { ExamServices } from '../../../Services/examServices';
import StudentNav from './StudentNav';
import Spinner from 'react-bootstrap/Spinner';

import { UserType } from 'interfaces/LiveExamUser';
import UserList from 'components/UserList';

const Loader = () => (
  <div style={{ display: 'grid', placeItems: 'center' }}>
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

export default function LiveExam() {
  const [users, setUsers] = useState<UserType[]>([]);
  const [search, setSearch] = useState('');
  const {
    loggedUsers,
    onlineUsers,
    offlineUsers,
    submittedUsers,
    absentUsers,
    blocked
  } = useMemo(() => {
    return {
      loggedUsers: users.filter((user) => user.student && user.student.isLogin),
      onlineUsers: users.filter((user) => user.online),
      offlineUsers: users.filter((user) => !user.online && user.socketId),
      submittedUsers: users.filter((user) => user.completionStatus),
      absentUsers: users.filter((user) => user.student && "socketId" in user === false),
      blocked: users.filter((user) => !user.active),
    };
  }, [users]);

  const checkUserInSearch = (current_users: UserType[]) => {
    const result = search
      ? current_users.filter((user) =>
        (user.student && user.student.name + ' ' + user.student.last_name)
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) || (user.student && user.student.registrationNumber)
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()),
      )
      : current_users;
    return result;
  };

  const params: any = useParams();
  const [examData, setExamData] = useState<any>({});
  const [tab, setTab] = useState<string>('all');

  const [loading, setLoading] = useState(true);

  const fetchUsers = async () => {
    await ExamServices.getAllUsers(params.examId).then((res) => {
      setUsers(res.data.data.users);
    });
    setLoading(false);
  };

  const handleResetTimeChange = async (mappingId: any, time: number) => {
    await ExamServices.resetMappingTime(mappingId, time * 60, params.examId).then(res => {
      if (res.status === 200) {
        toast.success("Mapping Time Reset")
        fetchUsers()
      }
    }).catch(e => {
      toast.error(e.response.data.message)
    })
  }

  const getExamData = async () => {
    await ExamServices.getExamById(params.examId)
      .then((res) => {
        if (res.status === 200) {
          setExamData(res.data.data);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const formatDate = (date: any) => {
    return moment(date).format('DD-MMM-YYYY hh:mm a');
  };

  const handleChange = (e: any) => {
    //  (e.target.value)
    if (e.target.value) {
      ExamServices.userAllowedStartTime(params.examId, {
        userAllowedStartTime: e.target.value,
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success('User Allowed Start time changed !');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleTimeChange = async (
    time: number,
    userid?: string,
    mappingid?: string,
  ) => {
    await ExamServices.addChangeMappingTime(
      {
        examId: examData._id,
        userId: userid ?? '',
        time: time * 60
      },
      mappingid ?? '',
    ).then((res) => {
      if (res.status === 200) {
        toast.success("Updated Time")
        fetchUsers();
      }
    }).catch((err) => {
      console.log(err)
    });
  };

  // const getAllLogsByMappingId = async () => {
  //   await LogService.getAllLogsByMappingId(mappingid).then((res) => {
  //     if (res.status === 200) {
  //       console.log(res.data);

  //     }
  //   }).catch((error) => {

  //     console.log(error);
  //   })
  // }

  // useEffect(() => {
  //   getAllLogsByMappingId();
  // }, [])

  useEffect(() => {
    getExamData();
    fetchUsers()
    const interval = setInterval(() => {
      fetchUsers();
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Container>
        <h4 className="mt-2">Live Exam</h4>
        {/* <h4 className='mt-2'>No Ongoing Exam Now</h4> */}
        <CardBasic
          cardClass="mt-2"
          body={
            <>
              <div className="d-flex justify-content-end"></div>
              <div className="d-flex justify-content-between mt-2">
                <div>
                  <div>
                    <span>
                      Name: <b>{examData?.examName}</b>
                    </span>
                  </div>
                  <div>
                    <span>
                      Subject: <b>{examData?.subject}</b>
                    </span>
                  </div>

                </div>
                {/* <div>
                  <div>
                    <span>
                      Created at <b>{formatDate(examData.createdAt)}</b>
                    </span>
                  </div>
                  <div>
                    <span>
                      Last updated at <b>{formatDate(examData.updatedAt)}</b>
                    </span>
                  </div>
                </div> */}
              </div>
              <hr />
              <div>
                <Table borderless responsive="md" className="xrg-text-12" size="sm">
                  <thead>
                    <tr>
                      <th>Time Type</th>
                      <th>Start Time/From</th>
                      <th>End Time/To</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-primary fw-bold">
                        Enabled Time
                      </td>
                      <td className="text-secondary">
                        {formatDate(examData.startTime)}
                      </td>
                      <td className="text-secondary">
                        {formatDate(examData.endTime)}
                      </td>
                      <td className="text-secondary">
                        ---
                      </td>

                    </tr>
                    <tr>
                      <td className="text-primary fw-bold">
                        Duration Time
                      </td>
                      <td className="text-secondary">
                        {formatDate(examData.userAllowedStartTime)}
                      </td>
                      <td className="text-secondary">
                        {formatDate(examData.userAllowedEndTime)}
                      </td>
                      <td className="text-secondary">
                        {moment(examData.userAllowedEndTime).diff(moment(examData.userAllowedStartTime), "minutes") + " min(s)"}
                      </td>

                    </tr>
                    <tr>
                      <td className="text-primary fw-bold">
                        Gate Closing Time
                      </td>
                      <td className="text-secondary">
                        {formatDate(examData.userAllowedTillTime)}
                      </td>
                      <td className="text-secondary">
                        ---
                      </td>
                      <td className="text-secondary">
                        ---
                      </td>


                    </tr>
                  </tbody>
                </Table>

              </div>
              <div className="d-flex justify-content-start align-items-center mt-3">
                {examData.tags
                  ? examData.tags.map((tag: any) => {
                    return (
                      <>
                        <Badge bg="secondary" className="me-2">
                          {tag}
                        </Badge>
                      </>
                    );
                  })
                  : ''}
              </div>
            </>
          }
        />

        <CardBasic
          cardClass="mt-2"
          body={
            <StudentNav
              tab={tab}
              setTab={setTab}
              searchValue={search}
              handleSearchChange={(value) => setSearch(value)}
              onlineCount={onlineUsers.length}
              offlineCount={offlineUsers.length}
              submittedCount={submittedUsers.length}
              blockedCount={blocked.length}
              loggedCount={loggedUsers.length}
              absentCount={absentUsers.length}
              userCount={users.length}
            />
          }
        />

        {tab === 'all' && (
          <CardBasic
            cardClass="mt-2"
            body={
              loading ? (
                <Loader />
              ) : (
                <UserList
                  onTimeSet={(time, id, mappingId) =>
                    handleTimeChange(time, id, mappingId)
                  }
                  users={checkUserInSearch(users)}
                  handleResetTimeChange={handleResetTimeChange}

                />
              )
            }
          />
        )}
        {tab === 'logged' && (
          <CardBasic
            cardClass="mt-2"
            body={
              loading ? (
                <Loader />
              ) : (
                <UserList
                  onTimeSet={(time, id, mappingId) =>
                    handleTimeChange(time, id, mappingId)
                  }
                  users={checkUserInSearch(loggedUsers)}
                  handleResetTimeChange={handleResetTimeChange}
                />
              )
            }
          />
        )}
        {tab === 'online' && (
          <CardBasic
            cardClass="mt-2"
            body={
              loading ? (
                <Loader />
              ) : (
                <UserList
                  onTimeSet={(time, id, mappingId) =>
                    handleTimeChange(time, id, mappingId)
                  }
                  users={checkUserInSearch(onlineUsers)}
                  handleResetTimeChange={handleResetTimeChange}
                />
              )
            }
          />
        )}
        {tab === 'offline' && (
          <CardBasic
            cardClass="mt-2"
            body={
              loading ? (
                <Loader />
              ) : (
                <UserList
                  onTimeSet={(time, id, mappingId) =>
                    handleTimeChange(time, id, mappingId)
                  }
                  users={checkUserInSearch(offlineUsers)}
                  handleResetTimeChange={handleResetTimeChange}
                />
              )
            }
          />
        )}
        {tab === 'submitted' && (
          <CardBasic
            cardClass="mt-2"
            body={
              loading ? (
                <Loader />
              ) : (
                <UserList
                  onTimeSet={(time, id, mappingId) =>
                    handleTimeChange(time, id, mappingId)
                  }
                  users={checkUserInSearch(submittedUsers)}
                  handleResetTimeChange={handleResetTimeChange}
                />
              )
            }
          />
        )}
        {tab === 'absent' && (
          <CardBasic
            cardClass="mt-2"
            body={
              loading ? (
                <Loader />
              ) : (
                <UserList
                  onTimeSet={(time, id, mappingid) =>
                    handleTimeChange(time, id, mappingid)
                  }
                  users={checkUserInSearch(absentUsers)}
                  handleResetTimeChange={handleResetTimeChange}
                />
              )
            }
          />
        )}
        {tab === 'blocked' && (
          <CardBasic
            cardClass="mt-2"
            body={
              loading ? (
                <Loader />
              ) : (
                <UserList
                  onTimeSet={(time, id, mappingid) =>
                    handleTimeChange(time, id, mappingid)
                  }
                  users={checkUserInSearch(blocked)}
                  handleResetTimeChange={handleResetTimeChange}
                />
              )
            }
          />
        )}
      </Container>
    </>
  );
}
function mappingid(mappingid: any) {
  throw new Error('Function not implemented.');
}

