/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Badge, Button, Table } from 'react-bootstrap';
import moment from 'moment';
import { ExamServices } from '../../../../Services/examServices';
import { toast } from 'react-toastify';
import ExamStatus from './ExamStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullhorn,
  faCheck,
  faCheckCircle,
  faCircleDot,
  faCircleQuestion,
  faClock,
  faGear,
  faPenAlt,
  faPencil,
  faRefresh,
  faReplyAll,
  faSquarePollVertical,
  faStamp,
  faTimesCircle,
  faTrash,
  faUpload,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import Toolbar from '../../../../components/Toolbar/Toolbar';
import UploadCandidateData from '../../../../components/Modals/UploadCandidateData.modal';
import CardBasic from '../../../../components/Cards/Card.basic';
import ExamUserTable from './ExamUserTable';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import PromptModal from '../../../../components/Modals/Prompt.modal';
import './ExamTime.css';
import { CSVDownload } from 'react-csv';
import TimeModal from './TimeModal';
interface ISelectedExam {
  examData: any;
  reloadData: any;
}

interface IInitialExamStatus {
  startAllowed: any;
  downloadData: any;
  userShow: any;
}

const TimeType = {
  examTime: {
    label: 'Exam Enabled(Reporting) and Disable(Maximum allowed Till) Time',
    type: 'examTime',
  },
  userAllowedTime: {
    label: 'Exam Duration Time',
    type: 'userAllowedTime',
  },
  userTillTime: {
    label: 'Gate Closing Time',
    type: 'userTillTime',
  },
};

export default function SelectedExamCard({
  examData,
  reloadData,
}: ISelectedExam) {
  const [examStatus, setExamStatus] = useState<IInitialExamStatus | any>({
    startAllowed: '',
    downloadData: '',
    userShow: '',
  });

  const params: any = useParams();
  const navigate = useNavigate();

  const [showCandidateDataModal, setShowCandidateDataModel] =
    useState<boolean>(false);
  const [confirmPrompt, setConfirmPrompt] = useState<boolean>(false);

  const [userData, setUserData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageCount, setPageCount] = useState(20);
  const [userAllowedExamTime, setUserAllowedExamTime] = useState({
    userAllowedStartTime: '',
    userAllowedEndTime: '',
  });

  const [examTimeModal, setExamTimeModal] = useState<boolean>(false);
  const [downloadData, setDownloadData] = useState<any>();
  const [date_type, setDate_type] = useState<{
    type: string;
    label: string;
  }>();

  const [examTime, setExamTime] = useState<{
    startTime: string;
    endTime: string;
  }>();
  const [userAllowedTime, setUserAllowedTime] = useState<{
    startTime: string;
    endTime: string;
  }>();
  const [userTillTime, setUserTillTime] = useState('');




  const formatDate = (date: any) => {
    return moment(date).format('DD-MMM-YYYY hh:mm a');
  };

  const examStatusData = async () => {
    await ExamServices.getExamStatus(params.examId)
      .then((res) => {
        if (res.status === 200) {
          setExamStatus(res.data);
        }
      })
      .catch((err) => {
        toast.error(err.response);
      });
  };

  const getAllMappedUser = async () => {
    await ExamServices.getAllUsers(params.examId).then((res) => {
      setUserData(res.data.data.users);
      setTotalPages(1)
    }).catch((err) => {
      toast.error('Unable to fetch User data');
    });
  };

  const makeExamLive = async () => {
    await ExamServices.makeExamLive(params.examId).then((res) => {
      if (res.status === 200) {
        toast.success('Exam is Live');
        reloadData();
      }
    });
  };


  const makeExamNotLive = async () => {
    await ExamServices.updateExamStatus(
      {
        startAllowed: false,
        downloadData: false,
        userShow: false,
      },
      params.examId,
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success('Exam is not live');
          setExamStatus(!examStatus);
        }
      })
      .catch((err) => {
        toast.error(err.response);
      });
  };

  const handleDeleteExamMapping = async () => {
    await ExamServices.deleteMappingForExam(params.examId).then((res) => {
      if (res.status === 200) {
        toast.success('Clear Mapping');
        reloadData();
        setConfirmPrompt(false);
      }
    });
  };

  const markExamCompleted = async () => {
    await ExamServices.markExamCompleted(params.examId).then((res) => {
      if (res.status === 200) {
        toast.success('Exam Status Changed');
        reloadData();
      }
    });
  };

  const getUserAllowedExamTime = async () => {
    await ExamServices.getUserAllowedExamTime(params.examId)
      .then((res) => {
        if (res.status === 200) {
          setUserAllowedExamTime(res.data.data);
        }
      })
      .catch((err) => { });
  };

  const handleTimeExamTime = async () => {
    // TODO : update on backend
    // await ExamServices.setExamDateAndTime(
    //   params.examId,
    //   examTime?.startTime ?? examData.startTime,
    //   examTime?.endTime ?? examData.end,
    // );
    // setExamTimeModal(false);
    await ExamServices.setExamDateAndTime(
      params.examId,
      examTime?.startTime ?? examData.startTime,
      examTime?.endTime ?? examData.endTime,
    ).catch((err) => console.log(err));
    setExamTimeModal(false);
    reloadData()
  };

  const handleUserTillTime = async () => {
    await ExamServices.setUserAllowedTillTime(
      params.examId,
      userTillTime ?? examData.userAllowedTillTime,
    ).catch((err) => console.error(err, true));
    setExamTimeModal(false);
    reloadData()
  };

  console.log(examData, "id")

  const handleUserAllowedTime = async () => {
    await ExamServices.setUserAllowedTime(
      params.examId,
      userAllowedTime?.startTime ?? userAllowedExamTime.userAllowedStartTime,
      userAllowedTime?.endTime ?? userAllowedExamTime.userAllowedEndTime,
    ).then(() => setExamTimeModal(false));
    reloadData()
  };

  const defaultTime =
    date_type === TimeType.examTime
      ? {
        start: examData.startTime,
        end: examData.endTime,
      }
      : date_type === TimeType.userAllowedTime
        ? {
          start: userAllowedExamTime.userAllowedStartTime,
          end: userAllowedExamTime.userAllowedEndTime,
        }
        : date_type === TimeType.userTillTime
          ? {
            start: examData.userAllowedTillTime,
          }
          : {};



  useEffect(() => {
    examStatusData();
    getUserAllowedExamTime();
  }, [examData, examTimeModal]);

  useEffect(() => {
    getAllMappedUser();
  }, [pageNumber, pageCount, examData, showCandidateDataModal]);

  return (
    <>
      <PromptModal
        setShow={setConfirmPrompt}
        show={confirmPrompt}
        body={
          <>
            <div>
              <p>Are you sure you want to Delete All Users?</p>
            </div>
          </>
        }
        submitButton={
          <Button
            variant="danger"
            className="ms-2"
            onClick={handleDeleteExamMapping}
          >
            Delete Users <FontAwesomeIcon icon={faTrash} />
          </Button>
        }
      />
      {examTimeModal && (
        <TimeModal
          show={examTimeModal}
          setShow={setExamTimeModal}
          type={date_type}
          onTimeChange={(start, end) => {
            const ISOStart = start;
            const ISOEnd = end;
            if (date_type === TimeType.examTime) {
              setExamTime({ startTime: ISOStart, endTime: ISOEnd });
              return;
            }
            if (date_type === TimeType.userAllowedTime) {
              setUserAllowedTime({ startTime: ISOStart, endTime: ISOEnd });
              return;
            }
            if (date_type === TimeType.userTillTime) {
              setUserTillTime(ISOStart);
              return;
            }
          }}
          onSubmit={() => {
            if (date_type === TimeType.examTime) {
              handleTimeExamTime();
              return;
            }
            if (date_type === TimeType.userAllowedTime) {
              handleUserAllowedTime();
              return;
            }
            if (date_type === TimeType.userTillTime) {
              handleUserTillTime();
              return;
            }
          }}
          defaultValues={defaultTime}
        />
      )}
      <CardBasic
        cardClass="mt-2"
        body={
          <>
            <div className="d-flex justify-content-end"></div>
            <div className="d-flex justify-content-between mt-2">
              <div>
                <div className="d-flex justify-content-start align-items-center">
                  <span>
                    Name: <b>{examData?.examName}</b>
                  </span>
                  <div className="ms-2">
                    <span>
                      {examStatus.startAllowed &&
                        examStatus.downloadData &&
                        examStatus.userShow ? (
                        <Badge bg="danger" className="me-2">
                          LIVE
                        </Badge>
                      ) : (
                        <Badge bg="info" className="me-2">
                          NOT LIVE
                        </Badge>
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <span>
                    Subject: <b>{examData?.subject}</b>
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span>
                    Created at <b>{formatDate(examData.createdAt)}</b>
                  </span>
                </div>

                <div>
                  <span>
                    Last updated at <b>{formatDate(examData.updatedAt)}</b>
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
              {examData.tags
                ? examData.tags.map((tag: any) => {
                  return (
                    <>
                      <Badge bg="secondary" className="me-2">
                        {tag}
                      </Badge>
                    </>
                  );
                })
                : ''}
            </div>
            <div>
              <span>
                Status:{' '}
                <b>{examData.markAsCompleted ? <span className="text-success">Completed <FontAwesomeIcon icon={faCheckCircle} className="ms-1" /></span> : <span className="text-danger">In Progress <FontAwesomeIcon icon={faCircleDot} className="ms-1" /></span>}</b>
              </span>
            </div>
            <div className="bg-primary-light mt-5">
              <ExamStatus data={examStatus} reloadData={reloadData} />
            </div>

            <hr />
            <h6 className="fw-bold">
              Exam Timings:
            </h6>
            <Table borderless responsive="md" className="xrg-text-12" size="sm">
              <thead>
                <tr>
                  <th>Time Type</th>
                  <th>Start Time/From</th>
                  <th>End Time/To</th>
                  <th>Duration</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-primary fw-bold">
                    Enabled Time
                  </td>
                  <td className="text-secondary">
                    {formatDate(examData.startTime)}
                  </td>
                  <td className="text-secondary">
                    {formatDate(examData.endTime)}
                  </td>
                  <td className="text-secondary">
                    ---
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faPencil} className="text-primary cursor-pointer" onClick={() => {
                      setDate_type(TimeType.examTime);
                      setExamTimeModal(true);
                    }
                    } />
                  </td>
                </tr>
                <tr>
                  <td className="text-primary fw-bold">
                    Duration Time
                  </td>
                  <td className="text-secondary">
                    {formatDate(examData.userAllowedStartTime)}
                  </td>
                  <td className="text-secondary">
                    {formatDate(examData.userAllowedEndTime)}
                  </td>
                  <td className="text-secondary">
                    {moment(examData.userAllowedEndTime).diff(moment(examData.userAllowedStartTime), "minutes") + " min(s)"}
                  </td>
                  <td>
                    <FontAwesomeIcon icon={faPencil} className="text-primary cursor-pointer" onClick={() => {
                      setDate_type(TimeType.userAllowedTime);
                      setExamTimeModal(true);
                    }
                    } />
                  </td>
                </tr>
                <tr>
                  <td className="text-primary fw-bold">
                    Gate Closing Time
                  </td>
                  <td className="text-secondary">
                    {formatDate(examData.userAllowedTillTime)}
                  </td>
                  <td className="text-secondary">
                    ---
                  </td>
                  <td className="text-secondary">
                    ---
                  </td>
                  <td className="text-secondary">
                    <div className='d-flex justify-content-between'>
                      <div>
                        <FontAwesomeIcon icon={faPencil} className="text-primary cursor-pointer" onClick={() => {
                          setDate_type(TimeType.userTillTime);
                          setExamTimeModal(true);
                        }
                        } />
                      </div>

                    </div>
                  </td>

                </tr>
              </tbody>
            </Table>
          </>

        }
      />

      <CardBasic
        cardClass="mt-3"
        body={
          <div className="d-flex justify-content-between align-items-center">


            <Toolbar
              values={[
                {
                  value: (
                    <>
                      {examStatus.startAllowed &&
                        examStatus.downloadData &&
                        examStatus.userShow ? (
                        <>
                          <FontAwesomeIcon
                            icon={faBullhorn}
                            size="sm"
                            className="me-2"
                          />
                          Stop Live Exam
                        </>
                      ) : (
                        <>
                          {' '}
                          <FontAwesomeIcon
                            icon={faBullhorn}
                            size="sm"
                            className="me-2"
                          />
                          Make Exam Live
                        </>
                      )}
                    </>
                  ),
                  onClick: () =>
                    examStatus.startAllowed &&
                      examStatus.downloadData &&
                      examStatus.userShow
                      ? makeExamNotLive()
                      : makeExamLive(),
                  variant:
                    examStatus.startAllowed &&
                      examStatus.downloadData &&
                      examStatus.userShow
                      ? 'danger'
                      : 'secondary',
                },
                {
                  value: (
                    <>
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="sm"
                        className="me-2"
                      />
                      {examData.markAsCompleted ? "Mark as Incomplete" : "Mark as Completed"}
                    </>
                  ),
                  onClick: markExamCompleted,
                  variant: examData.markAsCompleted ? "danger" : "success",
                },

                {
                  value: (
                    <>
                      <FontAwesomeIcon
                        icon={faGear}
                        size="sm"
                        className="me-2"
                      />
                      Check Live Exam
                    </>
                  ),
                  onClick: () => {
                    navigate('/admin-dash/live-exam/' + examData._id, {
                      state: 1,
                    });
                  },
                  variant: 'secondary',
                },
                {
                  value: (
                    <>
                      <FontAwesomeIcon
                        icon={faReplyAll}
                        size="sm"
                        className="me-2"
                      />
                      User Response
                    </>
                  ),
                  onClick: () => {
                    navigate('/admin-dash/responses/' + examData._id);
                  },
                  variant: 'secondary',
                },
                {
                  value: (
                    <>
                      <FontAwesomeIcon
                        icon={faCircleQuestion}
                        size="sm"
                        className="me-2"
                      />
                      Logs
                    </>
                  ),
                  onClick: () => {
                    navigate('/admin-dash/logs/' + examData._id);
                  },
                  variant: 'secondary',
                },
                {
                  value: (
                    <>
                      <FontAwesomeIcon
                        icon={faSquarePollVertical}
                        size="sm"
                        className="me-2"
                      />
                      Results
                    </>
                  ),
                  onClick: () => {
                    navigate('/admin-dash/exam/' + examData._id + "/" + "result");
                  },
                  variant: 'secondary',
                },
              ]}
              size="sm"
            />
          </div>
        }
      />

      <CardBasic
        cardClass="mt-3"
        title={
          <div className="d-flex justify-content-between">
            <h5>Mapped Candidates</h5>
            <Toolbar
              values={[
                {
                  value: (
                    <>
                      <FontAwesomeIcon
                        icon={faUpload}
                        size="sm"
                        className="me-2"
                      />
                      Upload File
                    </>
                  ),
                  onClick: () => {
                    setShowCandidateDataModel(true);
                  },
                  variant: 'secondary',
                },
                {
                  value: (
                    <>
                      <FontAwesomeIcon
                        icon={faXmark}
                        size="sm"
                        className="me-2"
                      />
                      Clear All Users
                    </>
                  ),
                  onClick: () => {
                    setConfirmPrompt(true);
                  },
                  variant: 'danger',
                }
              ]}
              size="sm"
            />
          </div>
        }
        body={
          <>
            <div className="mt-2">
              <ExamUserTable
                pageCount={pageCount}
                setPageCount={setPageCount}
                userData={userData}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                reloadData={getAllMappedUser}
              />
            </div>
          </>
        }
      />

      <UploadCandidateData
        show={showCandidateDataModal}
        setShow={setShowCandidateDataModel}
        candidateData={examData}
      />
    </>
  );
}
