import React, { useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { errorResponseHelper } from '../../helpers/request.helper'
import { ExamServices } from '../../Services/examServices'


interface IInitialValue {
    type: string,
    language: string,
    name: string,
    value?: any,
    child?: any 
}

interface IAddOption {
    closeMenu: any,
    reload: any
}



const AddOption = ({ closeMenu, reload }: IAddOption) => {

    const params: any = useParams();

    const [addOption, setAddOption] = useState<IInitialValue>({
        type: "text",
        language: "",
        name: "option1",
        value: "",
        child: ""
    });
    const [questionDataType, setQuestionDataType] = useState<any>();
    const [examLanguage, setExamLanguage] = useState<any>();
    const [selectFiles, setSelectFiles] = useState<any>();

    const [count, setCount] = useState<number>(0);

    const onValueChange = (e: any) => {
        setAddOption({ ...addOption, [e.target.name]: e.target.value })
    }

    const handleFileInput = (e: any) => {
        setSelectFiles(e.target.files);
    }

    const addOptionToQuestion = async (e: any) => {
        e.preventDefault();
        let formData = new FormData();

        formData.append("type", addOption.type);
        formData.append("language", addOption.language);
        formData.append("name", addOption.name);
        formData.append("value", addOption.value);
        formData.append("child", addOption.child)
        if (addOption.type === "dicom" || addOption.type === "image") if (selectFiles.length > 0) {
            formData.append("answerdata", selectFiles[0]);
        }



        await ExamServices.addOptionToQuestion(params.questionId, formData).then((res) => {
            if (res.status === 200) {
                toast.success("Option Added Successfully");
                reload();
                closeMenu();

            }
        }).catch(err => {
            errorResponseHelper(err)
        })
    }

    const getQuestionData = async () => {
        await ExamServices.getQuestionDataTypes().then((res) => {
            if (res.status == 200) {
                setQuestionDataType(res.data.data);
                setAddOption({ ...addOption, type: res.data.data[0] })
            }
        }).catch((err) => {
            errorResponseHelper(err);
        })
    }

    const getExamLanguage = async () => {
        await ExamServices.getExamLanguage(params.examId).then((res) => {
            if (res.status == 200) {
                setExamLanguage(res.data.data);
                setAddOption({ ...addOption, language: res.data.data[0] })

            }
        }).catch((err) => {
            errorResponseHelper(err);

        })
    }


    useEffect(() => {
        getExamLanguage();
        getQuestionData();
    }, [])



    return (
        <>
            <Form >
                <Form.Group className="mb-3">
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                        name="type"
                        onChange={(e) => onValueChange(e)}
                    >
                        {questionDataType?.length > 0 && questionDataType.map((type: any, i: any) => {
                            return <option value={type}>{type}</option>
                        })}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Select Language</Form.Label>
                    <Form.Select
                        name="language"
                        onChange={(e) => onValueChange(e)}
                    >
                        {examLanguage?.length > 0 && examLanguage.map((type: any, i: any) => (
                            <option value={type}>{type}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Select
                        name="name"
                        onChange={(e) => onValueChange(e)}
                    >
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                        <option value="option4">Option 4</option>
                        <option value="option5">Option 5</option>
                        <option value="option6">Option 6</option>
                        <option value="option7">Option 7</option>
                        <option value="option8">Option 8</option>
                        <option value="option9">Option 9</option>
                        <option value="option10">Option 10</option>
                    </Form.Select>
                </Form.Group>
                {addOption.type == "text" || addOption.type == "choice" || addOption.type =="boolean" || addOption.type == "trueorfalsewithdontknow" ?
                    <Form.Group className="mb-3">
                        <Form.Label>Value</Form.Label>
                        <Form.Control
                            type="text"
                            name="value"
                            onChange={(e) => onValueChange(e)}
                        />
                    </Form.Group> :
                    addOption.type == "choicewithtext" ?
                        <>
                            <Form.Group className="mb-3">
                                    <div>
                                        <Form.Label>Value</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="value"
                                            onChange={(e) => onValueChange(e)}
                                        />
                                    </div>
                                    <div className = "mt-2">
                                        <Form.Label>Child</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="child"
                                            onChange={(e) => onValueChange(e)}
                                        />
                                    </div>
                            </Form.Group>
                        </> :
                        <Form.Group className="mb-3">
                            <Form.Label>Select file</Form.Label>
                            <Form.Control
                                type="file"
                                name="answerdata"
                                onChange={handleFileInput}
                            />
                        </Form.Group>
                }
                <Button type="submit" onClick={addOptionToQuestion}>Add Option</Button>
            </Form>
        </>
    )
}

export default AddOption