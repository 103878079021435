import { faEdit, faPencil, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import Toolbar from "../../../../components/Toolbar/Toolbar";
import { ExamServices } from "../../../../Services/examServices";

interface IExamStatus {
  data: any;
  reloadData: any
}



const ExamStatus = ({ data, reloadData}: IExamStatus) => {

  const [editButton, setEditButton] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>();

  const onStatusChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.checked });
  };

  const updateExamStatus = async () => {
    await ExamServices.updateExamStatus(userData, data._id).then((res) => {
      if (res.status === 200) {
        toast.success("Status updated successfully.");
        setEditButton(false)
        reloadData();
      }
    }).catch(err => {
      toast.error("Something went wrong.");
    })
  };

  useEffect(() => {

    setUserData({
      downloadData: data.downloadData,
      startAllowed: data.startAllowed,
      userShow: data.userShow
    });
  }, [data]);

  return (
    <>
      <div className="d-flex justify-content-end mt-2">
        <Toolbar
          values={[{
            value: editButton ? <>
              <FontAwesomeIcon icon={faSave} size="sm" className="me-2" />
              Save
            </> : <>
              <FontAwesomeIcon icon={faPencil} size="sm" className="me-2" />
              Edit </>
            ,
            onClick: () => { editButton ? updateExamStatus() : setEditButton(!editButton) },
            variant: editButton ? "success" : "secondary"
          }]}
          size="sm"
        />
      </div>
      <div className="d-flex justify-content-start ms-3">
        <Form.Group
          className="mb-3 d-flex aling-items-center"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Start Allowed</Form.Label>
          <Form.Check
            name="startAllowed"
            className="me-4 ms-1"
            style={{
              textAlign: "center",
            }}
            checked={userData?.startAllowed === true}
            disabled={!editButton}
            defaultValue={userData?.startAllowed}
            onChange={(e) => onStatusChange(e)}
          />
        </Form.Group>
        <Form.Group
          className="mb-3 d-flex aling-items-center"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Download Data</Form.Label>
          <Form.Check
            name="downloadData"
            className="me-4 ms-1"
            style={{
              textAlign: "center",
            }}
            checked={userData?.downloadData === true}
            disabled={!editButton}
            defaultValue={userData?.downloadData}
            onChange={(e) => onStatusChange(e)}
          />
        </Form.Group>
        <Form.Group
          className="mb-3 d-flex aling-items-center"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>User Show</Form.Label>
          <Form.Check
            name="userShow"
            className="me-4 ms-1"
            style={{
              textAlign: "center",
            }}
            checked={userData?.userShow === true}
            disabled={!editButton}
            defaultValue={userData?.userShow}
            onChange={(e) => onStatusChange(e)}
          />
        </Form.Group>
      </div>
    </>
  );
};

export default ExamStatus;
