import React, { useState, useEffect } from 'react'
import { Form, Button, Container, Row, Col, Modal } from 'react-bootstrap'
import Select from 'react-select';



interface IChangeSectionModal {
    questionId: string;
    setHide: any;
    sections: any[];
    onConfirmMove:any
}


export default function ChangeSectionModal(props: IChangeSectionModal) {

    const [selectedSection, setSelectedSection] = useState<any>(undefined);

    return (
        <>
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => props.setHide()}
                show={props.questionId ? true : false}
            >
                <Modal.Body>
                    <>
                    <div className='fw-bold'>
                    Move Question Id {props.questionId} to Section
                    </div>
                        <Select
                            options={props.sections && props.sections.map((section) => {
                                return {
                                    value: section._id,
                                    label: section.name
                                }
                            })}
                            value={selectedSection}
                            onChange={(e) => { setSelectedSection(e) }}
                        />
                        <hr />
                        <Button variant="primary" type="button" onClick={() => {props.onConfirmMove(selectedSection.value)}}>
                            Move To Section
                        </Button>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}