import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DCMCreateExamModal from 'components/Modals/AddExamModal';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify'
import { ExamServices } from '../../../Services/examServices';
import { UserServices } from '../../../Services/userServices';
import Examcard from '../Dashboard/Home/Examcard';
import CreateExam from './CreateExam/CreateExam.index';




export default function Exam() {

    const token = localStorage.getItem("authKey");

    const [examData, setExamData] = useState<any[]>([]);

    const [showCreateExam, setShowCreateExam] = useState<boolean>(false);

    const getExamData = async () => {
        await ExamServices.getAllExams().then((res) => {
            if (res.status === 200) {
                setExamData(res.data.data.examData);
            }
        }).catch((err) => {
            toast.error(err);
        })

    }

    useEffect(() => {
        getExamData();
    }, [showCreateExam])

    return (
        <>
            <DCMCreateExamModal
                show={showCreateExam}
                setShow={setShowCreateExam}
            />
            <Container>
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <h4 className='mt-2'>Exams</h4>
                    <div>
                        <Button variant="primary" onClick={() => setShowCreateExam(true)} size="sm">
                            Create Exam <FontAwesomeIcon icon={faPlusCircle} className="ms-1"/>
                        </Button>
                    </div>
                </div>

                <Row>
                    <Col md={12}>
                        {examData ?
                            examData.map((data: any) => {
                                return (
                                    <div className='mt-3'>
                                        <Examcard examData={data} reloadData={getExamData} />
                                    </div>
                                )
                            })
                            : " "
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}