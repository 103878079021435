import React from 'react';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IBooleanIcon {
    status: boolean
    values: {
        true: any
        false: any
    }
    size?:any
}

export default function BooleanIcon (props: IBooleanIcon) {
    return (
        <FontAwesomeIcon icon={props.status ? props.values.true : props.values.false} className={props.status ? "text-success" : "text-danger"} size={props.size}/>
    )
}