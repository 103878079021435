import React, { useEffect, useState } from 'react'
import { Button, Card, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ResultSerivces } from 'Services/resultService';
import makeRequest from '../../../../api/makeRequest';

// TODO : display questions
// TODO : display marked answers

const StudentResult = () => {
    const { examId, userId }: any = useParams();
    const navigate: any = useNavigate();



    const [encrypedOption, setEncryptedOption] = useState<any>();
    const [question, setQuestion] = useState<any>();

    const getResult = async () => {
        await ResultSerivces.getResultResponse(examId, userId).then((res) => {
            if (res.status === 200) {
                setQuestion(res.data.data);
                console.log(res.data.data, "result");

            }
        }).catch((err) => {
            console.log(err);

        })
    }

    const getencryptedResponse = async () => {
        await ResultSerivces.getEncryptedOptionById(examId, userId).then((res) => {
            if (res.status === 200) {
                setEncryptedOption(res.data.data);
                console.log(res.data, "option result");

            }
        }).catch((err) => {
            console.log(err);

        })
    }

    useEffect(() => {
        getResult();
        getencryptedResponse();
    }, [])


    return (
        <Container className="my-3">
            <Button variant="primary"
                onClick={() => navigate("/admin-dash/exam/" + examId)}
            >Go back</Button>
            <br />

            <Card className="mt-3">
                <Card.Body>
                    <div>
                        <p>Questions:</p>
                        {question && question.responses.length > 0 ? question.responses.map((response: any) => {
                            return (
                                <>
                                    <p>Exam Id: {response.exam}</p>
                                    <div>
                                        {response.questionArr && response.questionArr.map((question: any, index: any) => {
                                            return (
                                                <>
                                                    <p>Section Id: {question.section}</p>
                                                    <div>
                                                        <p>Question Id: {question && question.questions.map((data: any) => {
                                                            return <p>{data}</p>
                                                        })}</p>
                                                    </div>
                                                </>

                                            )
                                        })}
                                    </div>
                                </>
                            )
                        }) : ""}
                    </div>
                </Card.Body>
            </Card>

        </Container>

    )
}

export default StudentResult