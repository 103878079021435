import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import decipherData from 'helpers/crypto.handler';
import { unZipFiles } from 'helpers/fs.helper';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ExamServices } from 'Services/examServices';
import { DcmViewerHook } from './DcmViewer.hook';

import "./DicomViewModal.css";
import CornerStoneDicomViewer from './CornerstoneDicomViewer';
import { useParams } from 'react-router-dom';

interface IDicomViewModal {
  show: boolean
  setShow: any
  dicomId?: any
  s3Link?: string
}

// https://dicom-data.s3.ap-south-1.amazonaws.com/

export default function DicomViewModal(props: IDicomViewModal) {

  const [dicomData, setDicomData] = useState<any>(undefined);
  const {questionId}:any = useParams();
  const [links, setLinks] = useState<any[]>([]);

  const getDecryptionKeys = async ()=>{
    return await ExamServices.getDicomSecret(props.dicomId).then(res=>{
      if(res.status === 200){
        console.log(res.data)
        return res.data.secretKey
      }
    })
  }

  const getDicomEncData = async() => {
    return await axios({
      url: props.s3Link +"/"+props.dicomId,
      method: 'GET'
    }).then((res)=>{
      if(res.status === 200){
        return res.data 
      }
    })
  }

  const loadDicomData = async() => {
    const key = await getDecryptionKeys();
    const enc_data = await getDicomEncData()
    console.log(key);
    // const decryptedData = decipherData(enc_data, key);
    const unzipped_data = await unZipFiles(enc_data);
    setDicomData(unzipped_data)
  }

  async function getQuestionData(){
    await ExamServices.getQuestionData(questionId)
      .then((res) => {
        if(res.status === 200){
          setLinks(res.data?.data?.links || []); 
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }

  // useEffect(() => {
  //   if(props.dicomId && props.s3Link){
  //     loadDicomData();
  //   }
  // },[props.dicomId, props.s3Link])

  useEffect(()=>{
    if(props.show && questionId){
      getQuestionData();
    }
  }, [props.show]);
  
  return (
    <>
      <Modal show={props.show} fullscreen={true} onHide={() => props.setShow(false)} centered className="dicom-modal-fullscreen">
        <Modal.Title className="text-white m-3"><FontAwesomeIcon icon={faClose} onClick={() => props.setShow(false)} className="me-3 dcm-link-cursor" /> Dicom Control View</Modal.Title>
        <Modal.Body>
          {links.length > 0 ? 
            <>
              {/* <DcmViewerHook
                dicomData={dicomData}
                onClose={() => props.setShow(false)}
                controlId="layer_group_modal_dicom"
              /> */}
              <CornerStoneDicomViewer onClose={() => props.setShow(false)} links={links} show={props.show} />
            </>
            :
            <div>Loading...</div>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}