import {
  faCheck,
  faCheckCircle,
  faCircle,
  faEllipsisVertical,
  faLinkSlash,
  faLock,
  faMinusCircle,
  faPlusCircle,
  faRefresh,
  faSignal,
  faTimesCircle,
  faUnlock,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useState } from 'react';
import { toast } from 'react-toastify';
import BooleanIcon from '../../../../components/Badge/BooleanIcon';
import BooleanStatus from '../../../../components/Badge/BooleanStatus';
import { UserServices } from '../../../../Services/userServices';
import DCMDropdown from '../../../../components/DropDown/DropDown.index';
import PromptModal from '../../../../components/Modals/Prompt.modal';
import { useNavigate, useParams } from 'react-router-dom';
import prettyMilliseconds from 'pretty-ms';

export interface ITableRow {
  data: any;
  reloadData?: any;
}

export default function TableRows({ data, reloadData }: ITableRow) {
  const navigate: any = useNavigate();
  const params: any = useParams();

  const [newPassword, setNewPassword] = useState({
    email: '',
    password: '',
  });

  const [showPrompt, setShowPrompt] = useState<boolean>(false);

  const formatDate = (date: any) => {
    return moment(date).format('DD-MMM-YYYY HH:mm');
  };

  const handleResetPassword = async (email: string) => {
    await UserServices.resetPasswordForUser({ email: email })
      .then((res) => {
        if (res.status === 200) {
          setNewPassword(res.data);
          setShowPrompt(true);
        }
      })
      .catch((err) => {
        toast.error('Something went wrong ');
        console.error(err);
      });
  };

  const handleDelete = async (id: string) => {
    await UserServices.deleteStudentMapping(id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status !== 404) {
            toast.success('User Deleted Successfully');
            reloadData();
          } else {
            toast.error(res.data.message);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDisable = async (id: string) => {
    await UserServices.toggleStudentMapping(id)
      .then((res) => {
        toast.success('User Disabled Successfully');
        reloadData();
      })
      .catch((err) => {
        console.error(err);
        toast.error('Something went wrong');
      });
  };

  const handleUnmarkCompleted = async (mappingId: string) => {
    await UserServices.unmarkCompleted(mappingId)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Unmarked! Please Refresh User Portal');
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error('Something went wrong');
      });
  };


  return (
    <>
      <PromptModal
        show={showPrompt}
        setShow={setShowPrompt}
        body={
          <>
            <div>
              <span>
                Password Changed to <b>{newPassword.password}</b>
              </span>
            </div>
          </>
        }
      />

      {data.map((data: any, index: number) => {

        return (
          <tr key={index}>
            <td className="text-secondary">{index + 1}</td>
            <td className="xrg-text-12 text-primary">{data.student && data.student.registrationNumber}</td>
            <td className="xrg-text-10">
              {data.student && data.student.username}
            </td>
            <td className="xrg-text-10">
              {moment(data.student && data.student.dob).format('DD-MM-YYYY')}
            </td>
            <td className="text-center">
              <BooleanIcon
                values={{ true: faSignal, false: faLinkSlash }}
                status={data.online}
                size="sm"
              />
            </td>
            <td>{data.student && data.student.centerId}</td>
            <td className="xrg-text-12">
              {data.student && data.student.name + ' ' + data.student.last_name}
            </td>
            <td className="text-muted xrg-text-10 text-monospace">
              {data.student && data.student.email}
            </td>
            <td className="text-center">
              <BooleanIcon
                values={{ true: faCheck, false: faXmark }}
                status={data && data.completionStatus}
                size="sm"
              />
            </td>
            <td className="text-center">
              <div>
                <BooleanIcon
                  values={{ true: faUnlock, false: faLock }}
                  status={data && data.active}
                  size="sm"
                />
              </div>
            </td>
            <td className="fw-bold text-secondary xrg-text-10">
              {data.remainingTime < 0
                ? '---'
                : prettyMilliseconds(Number(data.remainingTime) * 1000)}
            </td>
            {/* <td>{formatDate(data.createdAt)}</td>
            <td>{formatDate(data.updatedAt)}</td> */}
            <td>
              <DCMDropdown
                menuItems={[
                  {
                    label: (
                      <div className="text-secondary">
                        <FontAwesomeIcon icon={faRefresh} className="me-2" />
                        Reset Password
                      </div>
                    ),
                    onClick: () => handleResetPassword(data.student && data.student.email),
                  },
                  {
                    label: (
                      <div className="text-secondary">
                        {data.active === true ? (
                          <>
                            <FontAwesomeIcon
                              icon={faMinusCircle}
                              className="me-2"
                            />
                            Disable User
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              className="me-2"
                            />
                            Enable User
                          </>
                        )}
                      </div>
                    ),
                    onClick: () => handleDisable(data._id),
                  },
                  {
                    label: (
                      <div className="text-danger">
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="me-2"
                        />
                        Delete Mapping
                      </div>
                    ),
                    onClick: () => handleDelete(data._id),
                  },
                  {
                    label: (
                      <div className="text-success">
                        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                        View Result
                      </div>
                    ),
                    onClick: () =>
                      navigate(
                        '/admin-dash/exam/' +
                        params.examId +
                        '/result/' +
                        data.student._id,
                      ),
                  },
                  {
                    label: (
                      <div className="text-danger">
                        <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                        Unmark Completed
                      </div>
                    ),
                    onClick: () => {
                      handleUnmarkCompleted(data._id);
                    },
                  },
                ]}
                menuLabel={
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className="cursor-pointer"
                  />
                }
                title={
                  <div className="text-primary fw-bold">
                    {data.student && data.student.name + ' ' + data.student.last_name}
                  </div>
                }
              />
            </td>
          </tr>
        );
      })}
    </>
  );
}
