import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ResponsesServices } from 'Services/responsesServices';
import { LogService } from '../../../../Services/logService';

interface State {
    mappingId: string
}


const Index = () => {

    const location = useLocation();

    const state = location.state as State

    const [getLogs, setGetLogs] = useState();
    const [page, setPage] = useState<number>(1)
    const [count, setCount] = useState<number>(10)



    // const getAllLogsByMappingId = async () => {
    //     await LogService.getAllLogsByMappingId(state.mappingId).then((res) => {
    //         if (res.status === 200) {
    //             console.log(res.data);

    //         }
    //     }).catch((error) => {

    //         console.log(error);
    //     })
    // }

    // useEffect(() => {
    //     getAllLogsByMappingId();
    // }, [])

    return (
        <div>Logs</div>
    )
}

export default Index